import React, { useEffect, useState } from 'react'
import { apiFetch } from '../../service/security/apiFetch';
import { useHistory } from 'react-router-dom';
import { StyledTooltip, TransitionLeft } from '../../helper/utils';
import {
    Grid,
    Paper,
    CircularProgress,
    Zoom,
    Typography,
} from '@mui/material';
import Ressources from './StudentToolboxItem/Ressources';

export default function StudentToolbox({zoom, setOpenSnackbar, setText, setTransition, setSeverity, setOpenBackdrop}) {
    const [tab, setTab] = useState(false)
    const [ressources, setRessources] = useState(false)
    let history = useHistory()

    useEffect(() => {
        (async () => {
            const softSkill = await apiFetch('/soft_skills')
            if (softSkill && softSkill['hydra:member']) {
                setTab(softSkill['hydra:member'])
            } else {
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
                history.goBack()
            }
        })()
    }, [setOpenSnackbar, setTransition, setText, setSeverity, history])

    const handleClick = async (el) => {
        setOpenBackdrop(true)
        const getRessources = await apiFetch(`/resources?softSkills.uuid=${el.uuid}`)
        if (getRessources && getRessources['hydra:member']) {
            setRessources({main: el, ressources: getRessources['hydra:member']})
        } else {
            setText('Erreur lors du chargement des données')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            history.goBack()
        }
        setOpenBackdrop(false)
    }

    return (
        <div>
            {!ressources ? <Zoom in={zoom} timeout={700} style={{ transitionDelay: '200ms' }}>
                <div>
                    <Paper elevation={16}  sx={{ maxWidth: 1100, margin: 'auto', textAlign: 'center', p: 3}}>
                        <Typography variant='h4' color='primary' style={{fontWeight: 'bold'}}>PROGRESSEZ SUR LES SOFT SKILLS GRÂCE À LA BOÎTE À OUTILS !</Typography>
                    </Paper>
                    {tab ? <Paper elevation={16} sx={{ maxWidth: 1100, margin: 'auto', textAlign: 'center', pb: 3}} >
                        <Grid container spacing={4} sx={{textAlign: 'center', mt: 4}}>
                            {tab.map((el, index) => (
                                <StyledTooltip key={index} title={el.name}>
                                    <Grid  item xs={12} sm={4} md={3} sx={{mb: 4}}>
                                        <img src={el.isSelected ? el.iconVariant.contentUrl : el.icon.contentUrl} alt={el.name} width={'130px'} style={{cursor: 'pointer'}} onClick={() => handleClick(el)} />
                                    </Grid>
                                </StyledTooltip>
                            ))}
                        </Grid>
                    </Paper> : 
                    <div style={{textAlign: 'center', marginTop: 25}}>
                        <CircularProgress color='primary' />    
                    </div>}
                </div>
            </Zoom> : 
            ressources && <Ressources zoom={zoom} ressources={ressources} setRessources={setRessources} />}
        </div>
    )
}