import {
    Typography,
    Zoom,
    CircularProgress,
    Button,
    Toolbar,
    Box,
    Table,
    TablePagination,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Paper,
    TableContainer,
    TableBody,
    MenuItem,
    Menu,
    IconButton,
} from '@mui/material'
import React, { useEffect, useState, } from 'react'
import { useHistory } from 'react-router-dom'
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { ThemeProvider } from '@mui/styles'
import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles'
import { usePosts } from '../../hook/posts'
import { MoreVert, Delete, Edit } from '@mui/icons-material';
import { TransitionLeft } from '../../helper/utils';
import DeletedConfirmationPopup from '../../components/DeletedConfirmationPopup';
import { apiFetch } from '../../service/security/apiFetch';
import { handleFormError } from '../../service/security/error';
import AddPost from './AddPost';
import moment from 'moment';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {id: 'title', numeric: false, disablePadding: false, label: 'Titre', },
    {id: 'createdAt', numeric: true, disablePadding: false, label: 'Date', },
    {id: 'action', numeric: true, disablePadding: false, label: 'Actions', },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{color: '#01304A', fontWeight: 'bold'}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const { numSelected, handleAddPost } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%', color: '#ECA914' }}
                variant="h4"
                id="tableTitle"
                component="div"
            >
                Articles
            </Typography>
            <Button color='primary' variant='contained' onClick={handleAddPost}>Ajouter</Button>
        </Toolbar>
    );
};

export default function BLogManagement({ zoom, setSeverity, setText, setTransition, setOpenSnackbar, setOpenBackdrop, setUser }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('title');
    const [page, setPage] = useState(0);
    const [openManageModule, setOpenManageModule] = useState(false)
    const [photo, setPhoto] = useState([])
    const [disable, setDisable] = useState(false)
    const [isModified, setIsModified] = useState(false)
    const [selected, setSelected] = useState(false)
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [error, setError] = useState({})
    const [state, setState] = useState({
        title: '',
        createdAt: '',
        content: '',
    })
    const [files, setFiles] = React.useState([]);
    const [fileUrl, setFileUrl] = React.useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [locale] = useState('frFR');
    let history = useHistory()
    const editorRef = React.useRef(null);
    document.title = 'Wellmonday - Liste des articles'

    const  {
        posts,
        fetchPosts,
        deletePosts,
        updatePosts,
        createPosts,
    } = usePosts()

    useEffect(() => {
        (async () => {
            const fetch = await fetchPosts()
            if (fetch && fetch['hydra:description']) {
                setSeverity('error')
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                history.goBack()
            }
        })()
    }, [fetchPosts, setSeverity, setText, setTransition, setOpenSnackbar, history])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Avoid a layout jump when reaching the last page with empty posts.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * 30 - posts.length) : 0;

    const handleAddPost = () => {
        setOpenManageModule(true)
    }

    const managePosts = async () => {
        setDisable(true)
        setError({})
        const title = state.title
        const createdAt = state.createdAt
        const content = editorRef.current.getContent()
        let createPhoto = []
        let errorInPosition = null
        if (files.length !== 3 && photo && !isModified) {
            setDisable(false)
            return setError({photo: 'vous devez choisir 3 photos !'})
        }

        const requests = files.map(async (file) => {
            if (!file.position) {
                errorInPosition = true
                setError({position: 'Vous devez sélectionner une position'})
                setSeverity('error')
                setText('Erreur lors de l\'ajout de l\'article !')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                setDisable(false)
                return null
            }
            let dataPhoto = new FormData()
            dataPhoto.append('file', file && file[0])
            dataPhoto.append('position', file.position)
            const apiReturn = await apiFetch('/blog_post_objects', {
                method: 'POST',
                body: dataPhoto,
            }, true)
            createPhoto.push(apiReturn['@id'])
        })
        Promise.all(requests).then(async (request) => {
            const test = request.find((req) => req === false)
            if (typeof test !== 'undefined') return null
            if (errorInPosition) return null
            let body = {title: title, createdAt: createdAt, content: content }
            if (photo.length) {
                body.images = createPhoto
            }
    
            const manage = selected ? await updatePosts(selected.uuid, body, '/blog_posts') : await createPosts(body, '/blog_posts')
            if (manage && manage.uuid) {
                setText('L\'article a été crée')
                setTransition(() => TransitionLeft)
                setSeverity('success')
                setOpenSnackbar(true)
                setOpenManageModule(false)
                setState({
                    title: '',
                    createdAt: '',
                    content: '',
                })
                setFiles([])
                setPhoto([])
                setFileUrl([])
                handleCloseMenu()
            } else {
                const formError = handleFormError(manage, ['createdAt', 'title', 'content'])
                setError(formError)
                setSeverity('error')
                setText('Erreur lors de l\'ajout de l\'article !')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
            setDisable(false)
        })
    }

    const handleConfirmationDelete = () => {
        setOpenDeletePopup(!openDeletePopup)

    }

    const handleDelete = async () => {
        setAnchorEl(false)
        setOpenDeletePopup(!openDeletePopup)
        setOpenBackdrop(true)
        const deletedCoach = await deletePosts(selected.uuid)

        if (deletedCoach && deletedCoach.ok) {
            setText('L\'article a été supprimé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            handleCloseMenu()
        } else {
            setText((deletedCoach && deletedCoach['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setOpenBackdrop(false)
        setSelected(false)
    }

    const handleModifyPopup = () => {
        setState({
            title: selected.title,
            createdAt: selected.createdAt,
            content: selected.content,
        })
        setAnchorEl(false)
        setOpenManageModule(true)
        setIsModified(true)
    }

    const handleClose = () => {
        setState({
            title: '',
            createdAt: '',
            content: '',
        })
        setAnchorEl(false)
        setOpenManageModule(false)
        setSelected(false)
        setIsModified(false)
        setFiles([])
        setFileUrl([])
    }

    const handleClickMenu = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelected(row)
    };

    const handleCloseMenu = () => {
        setAnchorEl(false)
        setSelected(false)
    }

    return (
        <>
            {!openManageModule && <Zoom in={zoom} timeout={700}>
                <Box sx={{ width: '100%' }}>
                    <Paper elevation={16} sx={{ width: '100%', mb: 2 }}>
                        <EnhancedTableToolbar handleAddPost={handleAddPost} />
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                       posts.slice().sort(getComparator(order, orderBy)) */}
                                    {posts ? stableSort(posts, getComparator(order, orderBy))
                                        .slice(page * 30, page * 30 + 30)
                                        .map((row) => {

                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.uuid}
                                                >
                                                    <TableCell align="left">{row.title}</TableCell>
                                                    <TableCell align="right">{moment(row.createdAt).format('DD MMMM YYYY')}</TableCell>
                                                    <TableCell align="right">
                                                        <IconButton onClick={(e) => handleClickMenu(e, row)} style={{marginRight: 5}} ><MoreVert /></IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                    }) : <TableRow
                                        style={{
                                            height: (53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={3} />
                                        <TableCell>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                            {posts && <TablePagination
                                labelRowsPerPage=''
                                rowsPerPageOptions={[]}
                                component="div"
                                count={posts.length}
                                rowsPerPage={30}
                                page={page}
                                onPageChange={handleChangePage}
                            />}
                        </ThemeProvider>
                    </Paper>
                    <DeletedConfirmationPopup handleAccept={handleDelete} open={openDeletePopup} handleClose={handleConfirmationDelete} />
                    <Menu
                        id="menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseMenu}
                        MenuListProps={{
                            'aria-labelledby': 'button',
                        }}
                    >
                        <MenuItem onClick={() => handleModifyPopup()}><Edit style={{color: 'gray', marginRight: 8}} />Modifier</MenuItem>
                        <MenuItem onClick={() => handleConfirmationDelete()}><Delete style={{color: 'gray', marginRight: 8}} />Supprimer</MenuItem>
                    </Menu>
                </Box>
            </Zoom>}
            {openManageModule &&
            <Box sx={{ width: '100%' }}>
                <AddPost fileUrl={fileUrl} setFileUrl={setFileUrl} setIsModified={setIsModified} files={files} setFiles={setFiles} editorRef={editorRef} state={state} setState={setState} disable={disable} selected={selected} error={error} open={openManageModule} handleClose={handleClose} photo={photo} setPhoto={setPhoto} handleAdd={managePosts} />
            </Box>}
        </>
    );  
}