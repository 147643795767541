import {
    Typography,
    Zoom,
    CircularProgress,
    Button,
    ButtonGroup,
    Avatar,
    Toolbar,
    Box,
    Table,
    TablePagination,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Paper,
    TableContainer,
    TableBody,
    LinearProgress,
    TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TransitionLeft } from '../helper/utils'
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { ThemeProvider } from '@mui/styles'
import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles'
import AddCompanies from './CompaniesItem/AddCompanies'
import { useCompanies } from '../hook/companies'
import { handleFormError } from '../service/security/error';
import DeletedConfirmationPopup from '../components/DeletedConfirmationPopup';
import { apiFetch } from '../service/security/apiFetch';

let debounce = null

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {id: 'logo', numeric: false, disablePadding: false, label: 'Logo'},
    {id: 'name', numeric: true, disablePadding: false, label: 'Nom', },
    {id: 'address', numeric: true, disablePadding: false, label: 'Adresse', },
    {id: 'action', numeric: true, disablePadding: false, label: 'Action', },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{color: '#01304A', fontWeight: 'bold'}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const { numSelected, handleAddCompaniesPopup } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%', color: '#ECA914' }}
                variant="h4"
                id="tableTitle"
                component="div"
            >
                Liste des entreprises
            </Typography>
            <Button color='primary' variant='contained' onClick={handleAddCompaniesPopup}>Ajouter</Button>
        </Toolbar>
    );
};

export default function Companies({ zoom, setSeverity, setText, setTransition, setOpenSnackbar, setOpenBackdrop }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [error, setError] = useState({});
    const [page, setPage] = useState(0);
    const [selected, setSelected] = useState(false);
    const [locale] = useState('frFR');
    const [openPopup, setOpenPopup] = useState(false)
    const [disable, setDisable] = useState(false)
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [loading, setLoading] = useState(false)
    const [photo, setPhoto] = useState(false)
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    let history = useHistory()
    document.title = 'Wellmonday - Entreprise'

    const  {
        companies,
        view,
        totalItems,
        fetchCompanies,
        deleteCompanies,
        updateCompanies,
        createCompanies,
    } = useCompanies()

    useEffect(() => {
        (async () => {
            const fetch = await fetchCompanies()
            if (fetch && fetch['hydra:description']) {
                setSeverity('error')
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                history.goBack()
            }
        })()
    }, [fetchCompanies, setSeverity, setText, setTransition, setOpenSnackbar, history])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = async (event, newPage) => {
        setLoading(true)
        if (newPage > page) {
          await fetchCompanies(view['hydra:next'])
        } else if (newPage < page) {
          await fetchCompanies(view['hydra:previous'])
        }
        setLoading(false)
        setPage(newPage);
    };

    // Avoid a layout jump when reaching the last page with empty companies.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * 30 - companies.length) : 0;

    const handleAddCompaniesPopup = () => {
        setOpenPopup(true)
    }

    const manageCompany = async () => {
        setDisable(true)
        setError({})

        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData() 
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/logo_objects', {
                method: 'POST',
                body: dataPhoto,
            }, true)
        }

        if (createPhoto && createPhoto['hydra:description']) {
            setSeverity('error')
            setText(createPhoto['hydra:description'])
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
            setDisable(false)
            return
        }
        let body = { name: name, address: address }
        if (photo) {
            body = { name: name, logo: createPhoto && createPhoto['@id'], address: address }
        }
        
        const manage = selected ? await updateCompanies(selected.uuid, body, '/companies') : await createCompanies(body, '/companies')
        if (manage && manage.uuid) {
            setText(selected ? 'L\'entreprise a été modifiée' : 'L\'entreprise a été créée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            const formError = handleFormError(manage, ['name', 'address'])
            setError(formError)
            setSeverity('error')
            setText('Erreur lors de l\'ajout d\'une entreprise !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setOpenPopup(false)
        setDisable(false)
    }

    const handleConfirmationDelete = (event, row) => {
        setOpenDeletePopup(!openDeletePopup)
        setSelected(row)

    }

    const handleDelete = async () => {
        setOpenDeletePopup(!openDeletePopup)
        setOpenBackdrop(true)
        const deletedCompany = await deleteCompanies(selected.uuid)

        if (deletedCompany && deletedCompany.ok) {
            setText('L\'entreprise a été supprimée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText((deletedCompany && deletedCompany['hydra:description']) || 'Erreur lors de la suppression, vous devez d\'abord supprimer tous les référents et salariés de l\'entreprise !')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setOpenBackdrop(false)
        setSelected(false)
    }

    const handleModifyPopup = (event, row) => {
        history.push(`/entreprises/${row.uuid}`)
    }

    const handleClose = () => {
        setOpenPopup(false)
        setSelected(false)
    }

    const handleSearchCompanies = async (e) => {
        clearTimeout(debounce)
        if (e.target.value.length > 1) {
            debounce = setTimeout(async () => {
                await fetchCompanies(`/companies?name=${e.target.value}&itemsPerPage=30`)
            }, 500)
        }
        if (e.target.value.length === 0) {
            await fetchCompanies('/companies')
        }
    }
    
    return (
        <Zoom in={zoom} timeout={700}>
            <Box sx={{ width: '100%' }}>
                <Paper elevation={16} sx={{ width: '100%', mb: 2, p: 3 }}>
                    <Typography
                        sx={{ flex: '1 1 100%', color: '#ECA914' }}
                        variant="h5"
                        component="div"
                    >
                        Filtres
                    </Typography>
                    <TextField sx={{marginTop: 2, width: 300}} id='name' name='name' onChange={handleSearchCompanies} label={'Nom de l\'entreprise'} />
                </Paper>
                <Paper elevation={16} sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar handleAddCompaniesPopup={handleAddCompaniesPopup}/>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                   companies.slice().sort(getComparator(order, orderBy)) */}
                                {companies ? stableSort(companies, getComparator(order, orderBy))
                                    /* .slice(page * 30, page * 30 + 30) */
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.uuid}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="normal"
                                                >
                                                    <Avatar src={row.logo && row.logo.contentUrl} sx={{width: 70, height: 70}} />
                                                </TableCell>
                                                <TableCell align="right">{row.name}</TableCell>
                                                <TableCell align="right">{row.address}</TableCell>
                                                <TableCell align="right">
                                                    <ButtonGroup variant='contained' color='secondary'>
                                                        <Button sx={{color: 'white'}} onClick={(event) => handleModifyPopup(event, row)}>
                                                            Gérer
                                                        </Button>
                                                        <Button sx={{color: 'white'}} onClick={(event) => handleConfirmationDelete(event, row)}>
                                                            Supprimer
                                                        </Button>
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        );
                                }) : <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={2} />
                                    <TableCell>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                        {companies && <TablePagination
                            labelRowsPerPage=''
                            rowsPerPageOptions={[]}
                            component="div"
                            count={totalItems}
                            rowsPerPage={30}
                            page={page}
                            nextIconButtonProps={{ disabled: loading || page >= (totalItems / 30) - 1}}
                            backIconButtonProps={{ disabled: loading || page === 0 }} 
                            onPageChange={handleChangePage}
                        />}
                    </ThemeProvider>
                    {loading && <LinearProgress />}
                </Paper>
                <AddCompanies setAddress={setAddress} address={address} disable={disable} selected={selected} error={error} open={openPopup} handleClose={handleClose} handleAdd={manageCompany} photo={photo} setPhoto={setPhoto} name={name} setName={setName} />
                <DeletedConfirmationPopup handleAccept={handleDelete} open={openDeletePopup} handleClose={handleConfirmationDelete} />
            </Box>
        </Zoom>
    );  
}