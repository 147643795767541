import React, { useEffect, useState } from 'react'
import {
    Typography,
    Zoom,
    Avatar,
    Paper,
    Grid,
    MobileStepper,
    CircularProgress,
    IconButton,
    Button,
    Toolbar,
} from '@mui/material'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom';
import { apiFetch } from '../../../service/security/apiFetch';
import { download, getArrayOfSeanceEnded, shouldShowSignedButton, TransitionLeft, translateStatus } from '../../../helper/utils';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { handleSignePopup } from '../../../helper/CoachingActions';

export default function ManagerCoachingDetail({ zoom, setOpenSnackbar, setText, setTransition, setSeverity, setOpenBackdrop, setOpenSignePopup }) {
    const { id } = useParams()
    const [coaching, setCoaching] = useState(false)
    const [disable, setDisable] = useState(false)
    let history = useHistory()

    useEffect(() => {
        (async () => {
            const fetchCoaching = await apiFetch(`/coachings/${id}`)
            if (fetchCoaching && fetchCoaching.uuid) {
                setCoaching(fetchCoaching)
            } else {
                setSeverity('error')
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                history.goBack()
            }
        })()
    }, [id, history, setSeverity, setText, setTransition, setOpenSnackbar])

    const handleDownload = async (document) => {
        setDisable(true)
        const amazonLink = await apiFetch(document.downloadUrl, {}, false, false, false, false, true)
        
        if (amazonLink && amazonLink.signedUrl) {
            const aws3Doc = await apiFetch(`${amazonLink.signedUrl}`, {method: 'GET'}, true, true, true)
            if (aws3Doc.ok) {
                const blob = await aws3Doc.blob()
                download(blob, `${document.name} - ${moment(document.createdAt).format('DD MMMM YYYY')}`)
            }
        }
        setDisable(false)
    }

    const handleBack = () => {
        history.goBack()
    }
    
    return (
        <>
            <Toolbar>
                <div style={{flex: '1 1 100%'}}>
                    <Button onClick={handleBack} variant='contained' color='primary' sx={{mb: 5}}>Retour</Button>
                </div>
                {coaching && shouldShowSignedButton(coaching) && <Button disabled={disable} variant='contained' color='secondary' sx={{color: 'white', mb: 5}} onClick={() => handleSignePopup({coaching, setOpenBackdrop, setOpenSignePopup, setTransition, setText, setSeverity, setOpenSnackbar})}>Signer le contrat de coaching</Button>}
            </Toolbar>
            {coaching ?
                <> 
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <Zoom in={zoom} timeout={700}>
                                <Paper elevation={16} sx={{ pb: 5.3}}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <Avatar src={coaching.student.photo && coaching.student.photo.contentUrl} sx={{width: 100, ml: 5, height: 100}} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant='h6' sx={{fontWeight: 'bold'}} color='primary'>{coaching.student.user && coaching.student.user.firstName} {coaching.student.user && coaching.student.user.lastName}</Typography><br />
                                            <Typography variant='body1'>{coaching.student.company && coaching.student.company.name}</Typography>
                                            <Typography variant='body1'>{coaching.student.mobile && coaching.student.mobile}</Typography>
                                        </Grid>
                                    </Grid>
                                    {coaching.softSkill && <Typography variant='body2' sx={{ml: 3, mt: 10}}>Objectif: {coaching.softSkill.name}</Typography>}
                                </Paper>
                            </Zoom> 
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Zoom in={zoom} timeout={700} style={{ transitionDelay: '800ms' }}>
                                <Paper elevation={16} sx={{ pl: 3, pb: 2}}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4}>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' sx={{mt: 3, mb: 2}}>Documents</Typography>
                                    {coaching.documents && !!coaching.documents.length ? <Grid container spacing={3} sx={{mb: 2}}>
                                        {coaching.documents.map(document => {
                                            return <Grid item xs={12} sm={4} key={document.id}>
                                                <Typography variant='body1'>{document.name} <IconButton size="small" disabled={disable} style={{color: '#ECA914', cursor: 'pointer'}} onClick={() => handleDownload(document)}><AttachFileIcon /></IconButton></Typography>
                                                <Typography variant='caption' sx={{color: 'gray'}}>01/09/2021</Typography>
                                            </Grid>
                                        })}
                                    </Grid> : 
                                    <Typography variant='body1'>Pas de document pour le moment...</Typography>}
                                </Paper>
                            </Zoom>
                        </Grid>
                        <Grid item xs={6}>
                            <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
                                <Paper elevation={3} sx={{padding: 3}}>
                                    <Grid container spacing={3} sx={{mb: 1}}>
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant='body1'>{((getArrayOfSeanceEnded(coaching.seances) && getArrayOfSeanceEnded(coaching.seances).length)) || 0 } séance(s) réalisée sur {coaching.nbSeances}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MobileStepper
                                                variant="progress"
                                                steps={coaching.nbSeances + 1}
                                                position="static"
                                                activeStep={((getArrayOfSeanceEnded(coaching.seances) && getArrayOfSeanceEnded(coaching.seances).length)) || 0}
                                                sx={{ maxWidth: 600, mt: 0, flexGrow: 1, backgroundColor: '#fff' }}
                                                LinearProgressProps={{style: {width: '100%', height: '7px'}}}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div style={{display: 'flex', borderBottom: '3px solid #ECA914', paddingBottom: 10, marginBottom: 15}}>
                                        <Typography variant='h6' color='secondary'>Début : </Typography>
                                        <Typography variant='body1' style={{marginTop: 5, marginLeft: 50}}>{moment(coaching.createdAt).format('DD MMMM YYYY')}</Typography>
                                    </div>
                                    <Grid container spacing={3} sx={{textAlign: 'center', marginBottom: 2}}>
                                        <Grid item xs={12} sm={2}>
                                            <Typography variant='h6' color='secondary' style={{fontWeight: 'bold'}}>Séances</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Typography variant='h6' color='secondary' style={{fontWeight: 'bold'}}>Date</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Typography variant='h6' color='secondary' style={{fontWeight: 'bold'}}>Durée</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant='h6' color='secondary' style={{fontWeight: 'bold'}}>Coach</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Typography variant='h6' color='secondary' style={{fontWeight: 'bold'}}>Statut</Typography>
                                        </Grid>
                                    </Grid>
                                    {coaching.seances && coaching.seances.length ? coaching.seances.map((seance, index) => {
                                        return (
                                            <Grid container spacing={3} key={index} sx={{textAlign: 'center', marginBottom: 2}}>
                                                <Grid item xs={12} sm={2}>
                                                    <Typography variant='h6' color='secondary' style={{fontWeight: 'bold'}}>Séance {index + 1}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    {moment(seance.availability.startAt).format('DD MMMM YYYY à HH:mm')}
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    {seance.duration ? seance.duration : '0'} min
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    {coaching.coach && `${coaching.coach.user.firstName} ${coaching.coach.user.lastName}`}
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    {translateStatus(seance.status)}
                                                </Grid>
                                            </Grid>
                                        )
                                    }) : <Typography variant='h6'>Pas de séance pour le moment...</Typography>}
                                </Paper>
                            </Zoom>
                        </Grid>
                        <Grid item xs={6}>
                            {coaching.coach && <Zoom in={zoom} timeout={700} style={{ transitionDelay: '500ms' }}>
                                <Paper elevation={3} sx={{padding: 3}} style={{margin: 'auto', textAlign: 'center'}}>
                                    <Typography variant='h4' color='secondary' style={{fontWeight: 'bold'}}>Le coach</Typography>
                                    <Avatar alt='N' src={coaching.coach && coaching.coach.photo && coaching.coach.photo.contentUrl} style={{width: 150, height: 150, margin: 'auto', marginTop: 40}} />
                                    <div style={{justifyContent: 'center'}}>
                                        <Typography variant='h5' color='secondary' style={{fontWeight: 'bold', marginTop: 40}}>{coaching.coach.user.firstName} {coaching.coach.user.lastName[0]}.</Typography>
                                        <Typography variant='h6' color='secondary' style={{fontWeight: 'bold', marginTop: 10}}>{coaching.coach.description}.</Typography>
                                    </div>
                                </Paper>
                            </Zoom>}
                        </Grid>
                    </Grid>
                </> : 
                <div style={{textAlign: 'center'}}>
                    <CircularProgress />
                </div>
            }
        </>
    )
}