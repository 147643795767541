import { isAuth } from "./isAuth"
import AuthService from './handleTokenProvider'

export async function apiFetch(endpoint, options = {}, isMedia = false, isAws3 = false, isSpecial = false, customHeaders = false, force = false) {
    const user = isAuth()
    const token = user && user.token
    const headers = customHeaders ? customHeaders :
    !isMedia ? {
        'Content-Type': 'application/ld+json',
        'Authorization' : 'Bearer ' + token,
    } : {
        'Authorization' : 'Bearer ' + token,
    }
    
    const ClassicOptions = {
        headers,
        ...options
    }

    let response
    if (isAws3) {
        response = await fetch(endpoint)
    } else {
        try {
            response = await fetch(process.env.REACT_APP_BASE_URL + endpoint, ClassicOptions)
        } catch (e) {
            AuthService.logout()
            return
        }
    }

    let responseAfterRefresh = null

    if (response.status === 401) {
        responseAfterRefresh = await handleRefreshToken(user, endpoint, options, isAws3)
    }

    let responseData = null
    if (response && !responseAfterRefresh && specialCase(response, force) && !isSpecial) {
        responseData = response.status !== 204 ? await response.json() : response
    } else if (responseAfterRefresh && responseAfterRefresh.ok) {
        responseData = responseAfterRefresh.status !== 204 ? await responseAfterRefresh.json() : responseAfterRefresh
    } else if (responseAfterRefresh && !responseAfterRefresh.ok) {
        AuthService.logout()
        return
    }

    if (isSpecial) {
        return response
    }
    return !specialCase(response, force) ? response : responseData
}

async function handleRefreshToken(user, endpoint, options = {}, isAws3) {
    const data = { refresh_token: `${user.refresh_token}` }
    const refreshOptions = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data)
    }
    const refreshNeeded = await fetch (process.env.REACT_APP_BASE_URL + '/token/refresh', refreshOptions)

    if (refreshNeeded.ok) {
        const refreshNeededData = await refreshNeeded.json()
        localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(refreshNeededData))
        const OptionsAfterRefresh = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + refreshNeededData.token,
            },
            ...options
        }
        return isAws3 ? await fetch(endpoint, OptionsAfterRefresh) :  await fetch(process.env.REACT_APP_BASE_URL + endpoint, OptionsAfterRefresh)
    } else {
        return {ok: false}
    }
}

function specialCase(response, force) {
    return !response.url.endsWith('/download') || force
}
