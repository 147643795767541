import React, { useState } from 'react'
import {
    Zoom,
    Paper,
    Typography,
    Badge,
    Button,
} from '@mui/material/';
import { useHistory } from 'react-router-dom';
import { apiFetch } from '../../../service/security/apiFetch';
import { TransitionLeft } from '../../../helper/utils';
import SignePopup from '../../../components/SignePopup';

export default function ToDo({ zoom, dashboardData, setOpenSnackbar, setText, setTransition, setSeverity, setOpenBackdrop }) {
    let history = useHistory()
    const [openSignePopup, setOpenSignePopup] = useState(false)

    const handleClick = () => {
        history.push(`/coaching/${dashboardData.currentCoaching.uuid}/initialisation`)
    }

    const handleSignePopup = async () => {
        setOpenBackdrop(true)
        const getContract = await apiFetch(dashboardData.currentCoaching.contractSigningSessionUrl)

        if (getContract && getContract.sessionUrl) {
            setOpenSignePopup(getContract)
        } else {
            setTransition(() => TransitionLeft)
            setText('Impossible de signer le contrat pour le moment, veuillez réessayer plus tard !')
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setOpenBackdrop(false)
    }

    const handleAppointment = () => {
        history.push(`/prendre-un-rdv`)
    }

    const handleShowResource = () => {
        window.open(dashboardData.lastNoteSeance.resource.document.contentUrl, "_blank")
    }


    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '500ms' }}>
            <Paper elevation={16} sx={{padding: 3, }}>
                {(dashboardData.currentCoaching && dashboardData.currentCoaching.nextSeance && dashboardData.currentCoaching.coach) || !dashboardData.currentCoaching ?
                    <Typography variant='h3' sx={{fontSize: 28, fontWeight: 'bold', mb: 2, color: '#01304A', width: 90}}>
                        À faire 
                    </Typography>
                : <Badge color="primary" badgeContent="">
                    <Typography variant='h3' sx={{fontSize: 28, fontWeight: 'bold', mb: 2, color: '#01304A', width: 90}}>
                        À faire 
                    </Typography>
                </Badge> }
                {dashboardData.currentCoaching && dashboardData.currentCoaching.contractSigningSessionUrl ? <>
                    <Button variant='contained' color='secondary' onClick={handleSignePopup} sx={{width: '100%', height: 75, fontSize: {xl: 18, lg: 18, md: 15, sm: 18, xs: 15}, color: 'white'}}>Signer le contrat de coaching</Button>
                    <Typography variant='body1' sx={{fontSize: {xl: 20, lg: 20, md: 16, sm: 20, xs: 16}, mt: 2, padding: 8, textAlign: 'center'}}>
                        Nous vous invitons à signer votre contrat de coaching
                    </Typography>
                </> : dashboardData.currentCoaching && !dashboardData.currentCoaching.coach ? <>
                    <Button variant='contained' onClick={handleClick} sx={{width: '100%', height: 75, fontSize:  {xl: 18, lg: 18, md: 12, sm: 18, xs: 15}}}>Compléter la démarche de choix du coach</Button>
                    <Typography variant='body1' sx={{fontSize: {xl: 20, lg: 20, md: 15, sm: 18, xs: 15}, mt: 2, padding: {xl: 8, lg: 8, md: 3, sm: 4, xs: 8}, textAlign: 'center'}}>
                        Nous vous invitons à compléter les étapes d'évaluation de votre besoin pour pouvoir démarrer le coaching
                    </Typography>
                </> :
                !dashboardData.currentCoaching && !dashboardData.coachings ? <Typography variant='body1' sx={{fontSize: {xl: 20, lg: 20, md: 15, sm: 18, xs: 15}, mt: 2, padding: 8, textAlign: 'center'}}>
                    Vous allez être prochainement ajouté à un coaching, vous pourrez alors compléter la démarche de choix du coach. 
                </Typography> :
                dashboardData.currentCoaching && dashboardData.currentCoaching.nextSeance && dashboardData.currentCoaching.coach && <Typography variant='body1' sx={{fontSize: {xl: 20, lg: 20, md: 15, sm: 18, xs: 15}, mt: 2, padding: 8, textAlign: 'center'}}>
                    Pas de travail à faire. 
                </Typography>}
                {dashboardData.currentCoaching && !dashboardData.currentCoaching.nextSeance && dashboardData.currentCoaching.coach && 
                <Button variant='contained' onClick={handleAppointment} sx={{width: '100%', height: 75, fontSize: {xl: 18, lg: 18, md: 12, sm: 18, xs: 15}}}>Planifier une séance</Button>}
                <SignePopup open={openSignePopup} handleClose={() => setOpenSignePopup(false)} />
                {dashboardData.lastNoteSeance && dashboardData.lastNoteSeance.id && <div style={{marginTop: 30, borderTop: '6px dotted #ECA914'}}>
                    <Typography variant='h6' sx={{mt: 2}}>{dashboardData.lastNoteSeance.work}</Typography>
                    {dashboardData.lastNoteSeance && dashboardData.lastNoteSeance.resource && <Typography variant='body1' sx={{mt: 2}}><span style={{fontStyle: 'italic'}}>Ressource : </span>{dashboardData.lastNoteSeance.resource.title}</Typography>}
                    {dashboardData.lastNoteSeance && dashboardData.lastNoteSeance.resource && <Typography variant='body1' sx={{mt: 0}}><span style={{fontStyle: 'italic'}}>Theme : </span>{dashboardData.lastNoteSeance.resource.theme.name}</Typography>}
                    {dashboardData.lastNoteSeance && dashboardData.lastNoteSeance.resource && <Button onClick={handleShowResource} variant='contained' sx={{width: '100%', height: 75, fontSize: {xl: 18, lg: 18, md: 15, sm: 18, xs: 15}}}>Consulter la ressource</Button>}
                </div>}
                {dashboardData.coachings && dashboardData.coachings.length && dashboardData.coachings[0].status === 'ended' && !dashboardData.coachings[0].evaluation && 
                <Button variant='contained' onClick={() => history.push(`/coaching/${dashboardData.coachings[0].uuid}/evaluation`)} sx={{width: '100%', height: 75, fontSize: {xl: 18, lg: 18, md: 15, sm: 18, xs: 15}}}>EVALUEZ VOTRE PARCOURS DE COACHING</Button>}
            </Paper>
        </Zoom>
    )
}