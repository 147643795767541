import { Button, Grid, Snackbar, TextField, Zoom } from '@mui/material';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';
import { TransitionLeft } from '../../helper/utils';
import thingsToKnow from '../../img/7-choses-à-savoir.jpg'
import coachingGuide from '../../img/guide-coaching-à-distance.jpg'
import { apiFetch } from '../../service/security/apiFetch';
import { handleFormError } from '../../service/security/error';
import MuiAlert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import { isAuth } from '../../service/security/isAuth';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function FormDownload({ zoom , width }) {
    const [error, setError] = useState({})
    const [disable, setDisable] = useState(true)
    const [success, setSuccess] = useState(false)
    const [transition, setTransition] = useState(undefined);
    const [severity, setSeverity] = useState(false);
    const [text, setText] = useState(false);
    let history = useHistory()
    const { id } = useParams()
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
  
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let data = new FormData(e.target)
        const lastName = data.get('lastName')
        const firstName = data.get('firstName')
        const role = data.get('role')
        const company = data.get('company')
        const email = data.get('email')
        const phone = data.get('phone')

        const getBook = await apiFetch('/prospect_whitebooks',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email, firstName, lastName, company, phone, role, book: id === 'guide' ? 'remote-coaching-guide' : '7-things-to-know', notification: checked})
            }, true)

            if (getBook && !getBook['hydra:description']) {
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setText('Merci, vous allez recevoir par mail le livre blanc !')
            setSuccess(true)
            setTimeout(() => {
                history.push('/')
            }, 4000)
        } else {
            const formError = handleFormError(getBook, ['lastName', 'firstName', 'role', 'company', 'email', 'phone'])
            setError(formError)
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setText('Erreur lors du téléchargement, veuillez réessayer !')
            setSuccess(true)
        }
        setDisable(false)
    }

    const handleOnChange = (value) => {
        if (value) {
            setDisable(false)
        }
    }

    const handleClose = () => {
        setSuccess(false);
    }
    
    return (
        <Zoom in={zoom} timeout={700}>
            <div>
                <Grid container spacing={3} sx={{mt: 2, maxWidth: 1500, margin: 'auto'}}>
                    <Grid item xs={12} sm={6}>
                        <img src={id === 'guide' ? coachingGuide : thingsToKnow} alt='7 choses à savoir' width='100%' style={{marginBottom: 10}} />
                        {id === 'guide' ? <p>Obtenez gratuitement le guide du coaching à distance, pour en connaître les spécificités et les bonnes questions à se poser lors du choix d'un prestataire.<br /><br /> Bonne lecture ! <br /><br /> L'équipe Wellmonday</p> : <p>Obtenez gratuitement le livre blanc "Les 7 choses à savoir avant de démarrer un coaching à distance", pour vous préparer au mieux et choisir le bon coach.<br /><br /> Bonne découverte !<br /><br /> L'équipe Wellmonday</p>}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <form noValidate onSubmit={ handleFormSubmit }>
                            <TextField sx={{width: '100%', maxWidth: 500, mt: 3}} error={!!error.lastName} helperText={error.lastName} id="lastName" name="lastName" label="Nom" /><br />
                            <TextField sx={{width: '100%', maxWidth: 500, mt: 3}} error={!!error.firstName} helperText={error.firstName} id="firstName" name="firstName" label="Prénom" /><br />
                            <TextField sx={{width: '100%', maxWidth: 500, mt: 3}} error={!!error.role} helperText={error.role} id="role" name="role" label="Fonction" /><br />
                            <TextField sx={{width: '100%', maxWidth: 500, mt: 3}} error={!!error.company} helperText={error.company} id="company" name="company" label="Entreprise" /><br />
                            <TextField sx={{width: '100%', maxWidth: 500, mt: 3}} error={!!error.email} helperText={error.email} id="email" name="email" label="E-mail professionnel" /><br />
                            <TextField sx={{width: '100%', maxWidth: 500, mt: 3, mb: 3}} error={!!error.phone} helperText={error.phone} id="phone" name="phone" label="Téléphone" /><br />
                            <FormControlLabel
                                value={checked}
                                onChange={handleChange}
                                control={<Checkbox />}
                                style={{marginBottom: 20, color: 'dark gray', maxWidth: 500}}
                                label="J'accepte d'être contacté par l'un de vos conseillers pour éventuellement recevoir des informations en fonctions de mes besoins."
                            />
                            {!isAuth() ? <ReCAPTCHA
                                sitekey='6Ld6oDUgAAAAADoX014qQ3gomAMI2unCyJxGqwdH'
                                /* sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' */
                                onChange={handleOnChange}
                            >

                            </ReCAPTCHA> : <br />}
                            <Button disabled={disable} sx={{mt: 3}} variant='contained' type="submit">Télécharger</Button>
                        </form>
                    </Grid>
                </Grid>
                {success && <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={success}
                    onClose={handleClose}
                    TransitionComponent={transition}
                    key={transition ? transition.name : ''}
                >
                    {severity && <MuiAlert elevation={6} variant="filled" severity={severity} >{text}</MuiAlert>}
                </Snackbar>}
            </div>
        </Zoom>
    )
}