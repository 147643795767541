import {
    Typography,
    Zoom,
    CircularProgress,
    Button,
    Toolbar,
    Box,
    Table,
    TablePagination,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Paper,
    TableContainer,
    TableBody,
    MenuItem,
    Menu,
    IconButton,
} from '@mui/material'
import React, { useEffect, useState, } from 'react'
import { useHistory } from 'react-router-dom'
import { TransitionLeft } from '../helper/utils'
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { ThemeProvider } from '@mui/styles'
import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles'
import DeletedConfirmationPopup from '../components/DeletedConfirmationPopup'
import { handleFormError } from '../service/security/error';
import { MoreVert, Delete, Edit } from '@mui/icons-material';
import { useUsers } from '../hook/users';
import ManageUsers from './UsersItem/ManageUsers';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {id: 'firstName', numeric: false, disablePadding: false, label: 'Prénom', },
    {id: 'lastName', numeric: true, disablePadding: false, label: 'Nom', },
    {id: 'email', numeric: true, disablePadding: false, label: 'Email', },
    {id: 'action', numeric: true, disablePadding: false, label: 'Actions', },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{color: '#01304A', fontWeight: 'bold'}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const { numSelected, handleAddUserPopup } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%', color: '#ECA914' }}
                variant="h4"
                id="tableTitle"
                component="div"
            >
                Utilisateurs
            </Typography>
            <Button color='primary' variant='contained' onClick={handleAddUserPopup}>Ajouter</Button>
        </Toolbar>
    );
};

export default function UsersPc({ zoom, setSeverity, setText, setTransition, setOpenSnackbar, setOpenBackdrop }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('lastName');
    const [page, setPage] = useState(0);
    const [openPopup, setOpenPopup] = useState(false)
    const [disable, setDisable] = useState(false)
    const [role, setRole] = useState('')
    const [selected, setSelected] = useState(false)
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [error, setError] = useState({})
    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    })
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [locale] = useState('frFR');
    let history = useHistory()
    document.title = 'Wellmonday - Liste des utilisateurs'

    const  {
        users,
        fetchUsers,
        deleteUsers,
        updateUsers,
        createUsers,
    } = useUsers()

    useEffect(() => {
        (async () => {
            const fetch = await fetchUsers()
            if (fetch && fetch['hydra:description']) {
                setSeverity('error')
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                history.goBack()
            }
        })()
    }, [fetchUsers, setSeverity, setText, setTransition, setOpenSnackbar, history])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Avoid a layout jump when reaching the last page with empty users.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * 30 - users.length) : 0;

    const handleAddUserPopup = () => {
        setOpenPopup(true)
    }

    const manageUsers = async () => {
        setDisable(true)
        setError({})
        const firstName = state.firstName
        const lastName = state.lastName
        const email = state.email
        const password = state.password

        let body = { user: { firstName: firstName, lastName: lastName, email: email, roles: [role] }}
        if (selected) {
            body = { firstName: firstName, lastName: lastName, email: email, roles: [role] }
        }
        if (password) {
            body.user.password = password
        }

        const manage = selected ? await updateUsers(selected.user.uuid, body, '/pros_consultes') : await createUsers(body, '/pros_consultes')
        if (manage && (manage.uuid || manage.email)) {
            setText('L\'utilisateur a été crée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenPopup(false)
            setState({
                firstName: '',
                lastName: '',
                email: '',
                password: '',
            })
            setError({})
            setRole('')
            handleCloseMenu()
        } else {
            const formError = handleFormError(manage, ['user.lastName', 'user.firstName', 'user.email', 'user.roles', 'user.plainPassword', 'user.password'])
            setError(formError)
            setSeverity('error')
            if (manage && manage['hydra:description'] === 'Access Denied.') {
                setText('Vous n\'avez pas le droit de modifier cet utilisateur')
            } else {
                setText('Erreur lors de l\'ajout du utilisateur !')
            }
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDisable(false)
    }

    const handleConfirmationDelete = () => {
        setOpenDeletePopup(!openDeletePopup)

    }

    const handleDelete = async () => {
        setOpenDeletePopup(!openDeletePopup)
        setAnchorEl(false)
        setOpenBackdrop(true)
        const deletedCoach = await deleteUsers(selected.uuid)

        if (deletedCoach && deletedCoach.ok) {
            setText('L\'utilisateur a été supprimé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            handleCloseMenu()
        } else {
            if (deletedCoach && deletedCoach['hydra:description'] === 'Access Denied.') {
                setText('Vous n\'avez pas le droit de supprimer cet utilisateur')
            } else {
                setText((deletedCoach && deletedCoach['hydra:description']) || 'Erreur lors de la suppression')
            }
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setOpenBackdrop(false)
        setSelected(false)
    }

    const handleModifyPopup = () => {
        setState({
            firstName: selected.user.firstName,
            lastName: selected.user.lastName,
            email: selected.user.email,
            password: selected.user.password,
        })
        setRole(selected.user.roles[0])
        setOpenPopup(true)
    }

    const handleClose = () => {
        setState({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
        })
        setError({})
        setRole('')
        setOpenPopup(false)
        setSelected(false)
    }

    const handleClickMenu = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelected(row)
    };

    const handleCloseMenu = () => {
        setAnchorEl(false)
        setSelected(false)
    }

    return (
        <Zoom in={zoom} timeout={700}>
            <Box sx={{ width: '100%' }}>
                <Paper elevation={16} sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar handleAddUserPopup={handleAddUserPopup} />
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                   users.slice().sort(getComparator(order, orderBy)) */}
                                {users ? stableSort(users, getComparator(order, orderBy))
                                    .slice(page * 30, page * 30 + 30)
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.uuid}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="normal"
                                                >
                                                    {row.user.firstName}
                                                </TableCell>
                                                <TableCell align="right">{row.user.lastName}</TableCell>
                                                <TableCell align="right">{row.user.email}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton onClick={(e) => handleClickMenu(e, row)} style={{marginRight: 5}} ><MoreVert /></IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                }) : <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={3} />
                                    <TableCell>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                        {users && <TablePagination
                            labelRowsPerPage=''
                            rowsPerPageOptions={[]}
                            component="div"
                            count={users.length}
                            rowsPerPage={30}
                            page={page}
                            onPageChange={handleChangePage}
                        />}
                    </ThemeProvider>
                </Paper>
                <ManageUsers role={role} setRole={setRole} state={state} setState={setState} disable={disable} selected={selected} error={error} open={openPopup} handleClose={handleClose} handleAdd={manageUsers} />
                <DeletedConfirmationPopup handleAccept={handleDelete} open={openDeletePopup} handleClose={handleConfirmationDelete} />
                <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                        'aria-labelledby': 'button',
                    }}
                >
                    <MenuItem onClick={() => handleModifyPopup()}><Edit style={{color: 'gray', marginRight: 8}} />Modifier</MenuItem>
                    <MenuItem onClick={() => handleConfirmationDelete()}><Delete style={{color: 'gray', marginRight: 8}} />Supprimer</MenuItem>
                </Menu>
            </Box>
        </Zoom>
    );  
}