import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useMemo, useEffect } from 'react'
import './App.css';
import { routes } from './service/routes/routes';
import { isAuth } from './service/security/isAuth';
import MainMenu from './scenes/MainMenu';
import { Route, Redirect } from 'react-router-dom'
import Error from './scenes/Error';
import Login from './scenes/HomeItem/Login';
import ValidateUser from './scenes/ValidateUser';
import { ResetPassword } from './scenes/ResetPassword';
import { HandleResetPassword } from './scenes/HandleResetPassword';
import ReactGA from 'react-ga';
import { isKnownRoutes } from './helper/utils';
const TRACKING_ID = "G-H782SVD82E";

ReactGA.initialize(TRACKING_ID);

function App() {
    const theme = useMemo(
        () => createTheme({
          palette: {
            primary: {
              light: '#4c31ac',
              main: '#ECA914',
              dark: '#B77E00',
              contrastText: '#fff',
            },
            secondary: {
              light: '#adffff',
              main: '#01304A',
              dark: '#074F77',
              contrastText: '#000',
            },
            background: {
              default: "#F8F8F8"
            }
          },
          typography: {
            fontFamily: [
              'Aileron Regular',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(',')
          }
        }),
        [],
    )

    window.location.pathname !== '/' && window.location.pathname !== '/login' && window.location.pathname !== '/dashboard' && localStorage.setItem('LAST_URL', window.location.pathname)
    
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="App" style={{margin: 0}}>
            <ThemeProvider theme={theme}>
                {isAuth() && <Route path={routes.home}><MainMenu /></Route>}
                {!isAuth() && <Route path={routes.resetPassword} exact><ResetPassword /></Route>}
                {!isAuth() && <Route path={[routes.login, routes.home]} exact><Login /></Route>}
                {!isAuth() && <Route path={routes.resetPasswordId} exact><HandleResetPassword /></Route>}
                {!isAuth() && <Route path={routes.validateUser} exact><ValidateUser /></Route>}
                {!isAuth() && <Route path={'/error'}><Error /></Route>}
                {!isKnownRoutes(window.location.pathname) && !window.location.pathname.startsWith('/twilio/video/') && <Route render={() => <Redirect to="/" />} />}
            </ThemeProvider>
        </div>
    );
}

export default App;
