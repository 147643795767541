import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import {hasRoles} from '../security/hasRoles'


export const AdminRoute = ({component: Component, ...rest}) => {
    let history = useHistory()

    return (
        <Route {...rest} render= {(props) => (
            hasRoles() === 'admin' ? <Component {...props} zoom={rest.zoom} setUser={rest.setUser} setSeancesSuggested={rest.setSeancesSuggested} setOpenManageExtensionPopup={rest.setOpenManageExtensionPopup} coachings={rest.coachings} setCoachings={rest.setCoachings} setOpenBackdrop={rest.setOpenBackdrop} setOpenSnackbar={rest.setOpenSnackbar} setText={rest.setText} setTransition={rest.setTransition} setSeverity={rest.setSeverity} /> : history.goBack()
        )}/>
    )
}