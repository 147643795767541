import { Paper, Typography, Zoom, Grid, Radio, TextField, Button, FormHelperText} from '@mui/material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { TransitionLeft } from '../helper/utils';
import { apiFetch } from '../service/security/apiFetch';
import { handleFormError } from '../service/security/error';

export default function CoachingEvaluation({zoom, setOpenSnackbar, setText, setTransition, setSeverity, user}) {
    const [selectedValue, setSelectedValue] = useState({});
    const [commentValue, setCommentValue] = useState({});
    const [error, setError] = useState({});
    const { id } = useParams()
    let history = useHistory()
    document.title = 'Wellmonday - Évaluation'

    const handleChangeRadio = (event) => {
        setSelectedValue({...selectedValue, [event.target.name]: event.target.value});
    };

    const handleChangeComment = (event) => {
        setCommentValue({...commentValue, [event.target.id]: event.target.value});
    };

    const handleSendEvaluation = async () => {
        setError({})
        const postEvaluation = await apiFetch('/evaluations', {
            method: 'POST',
            body: JSON.stringify({
                "coaching": `/coachings/${id}`,
	            "coachRelationQuality": translateValue(selectedValue['radio-buttons-1']),
	            "coachRelationQualityComment": commentValue.coachRelationQualityComment,
	            "coachSupport": translateValue(selectedValue['radio-buttons-2']),
	            "coachSupportComment": commentValue.coachSupportComment,
	            "coachAdaptation": translateValue(selectedValue['radio-buttons-3']),
	            "coachAdaptationComment": commentValue.coachAdaptationComment,
	            "videoFrequencyAndDuration": translateValue(selectedValue['radio-buttons-4']),
	            "videoFrequencyAndDurationComment": commentValue.videoFrequencyAndDurationComment,
	            "platformQuality": translateValue(selectedValue['radio-buttons-5']),
	            "platformQualityComment": commentValue.platformQualityComment,
	            "positiveElements": commentValue.positiveElements,
	            "negativeElements": commentValue.negativeElements,
	            "missingElements": commentValue.missingElements,
                "freeComment": commentValue.freeComment || null,
	            "platformRecommendation": translateValue(selectedValue['radio-buttons-9']),
	            "coachingMeetExpectations": translateValue(selectedValue['radio-buttons-10']),
            })
        })

        if (postEvaluation && !postEvaluation['hydra:description']) {
            setText('Merci pour le retour sur votre parcours de coaching !')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            history.push('/')
        } else {
            if (postEvaluation && postEvaluation['hydra:description'] && postEvaluation['hydra:description'].startsWith('coaching:')) {
                setText('Vous ne pouvez pas recommencer l\'évaluation de votre parcours !')
                history.push('/')
            } else {
                const formError = handleFormError(postEvaluation, [
                    'coachRelationQuality',
                    'coachRelationQualityComment',
                    'coachSupport',
                    'coachSupportComment',
                    'coachAdaptation',
                    'coachAdaptationComment',
                    'videoFrequencyAndDuration',
                    'videoFrequencyAndDurationComment',
                    'platformQuality',
                    'platformQualityComment',
                    'positiveElements',
                    'negativeElements',
                    'missingElements',
                    'freeComment',
                    'platformRecommendation',
                    'coachingMeetExpectations',
                ])
                setError(formError)
                setText('Une erreur est survenue pendant l\'envoie de votre evaluation, assurez-vous d\'avoir bien coché toutes les cases et recommencer !')
            }
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '500ms' }}>
            <Paper elevation={16} sx={{maxWidth: 1200, margin: 'auto', textAlign: 'center', p: '10px 100px 20px 100px'}}>
                <Typography variant='h4' sx={{textAlign: 'center', mt: 2, pt: 2}} color='primary'>
                    EVALUEZ VOTRE PARCOURS DE COACHING
                </Typography>
                <Typography variant='h6' sx={{textAlign: 'left', mt: 2, pt: 2}} color='secondary'>
                    Votre parcours de coaching est maintenant terminé ! Nous avons encore besoin de vous quelques minutes, pour évaluer votre satisfaction. Merci pour votre contribution !
                </Typography>
                <Grid container spacing={3} sx={{mt: 2, textAlign: 'left'}}>
                    <Grid item xs={12} sm={8}>
                        Comment évaluez-vous :
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        Très bien
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        Bien
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        Moyen
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        Faible
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{mt: 2, textAlign: 'left'}}>
                    <Grid item xs={12} sm={8}>
                        1. La qualité de la relation avec le.la coach ?
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-1'] === '1tb'}
                            onChange={handleChangeRadio}
                            value="1tb"
                            name="radio-buttons-1"
                            inputProps={{ 'aria-label': 'TB' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-1'] === '1b'}
                            onChange={handleChangeRadio}
                            value="1b"
                            name="radio-buttons-1"
                            inputProps={{ 'aria-label': 'B' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-1'] === '1m'}
                            onChange={handleChangeRadio}
                            value="1m"
                            name="radio-buttons-1"
                            inputProps={{ 'aria-label': 'M' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-1'] === '1f'}
                            onChange={handleChangeRadio}
                            value="1f"
                            name="radio-buttons-1"
                            inputProps={{ 'aria-label': 'F' }}
                        />
                    </Grid>
                    <div style={{width: '100%'}}><FormHelperText sx={{color: 'red', textAlign: 'end', marginRight: 5}}>{error.coachRelationQuality}</FormHelperText></div>
                    <TextField
                        value={commentValue['coachRelationQualityComment']}
                        onChange={handleChangeComment}
                        id="coachRelationQualityComment"
                        label="Commentaires eventuels"
                        multiline
                        rows={4}
                        sx={{m: 3, width: '100%'}}
                        error={!!error.coachRelationQualityComment}
                        helperText={error.coachRelationQualityComment}
                    />
                </Grid>
                <Grid container spacing={3} sx={{mt: 2, textAlign: 'left'}}>
                    <Grid item xs={12} sm={8}>
                        2. L'accompagnement par le.la coach ?
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-2'] === '2tb'}
                            onChange={handleChangeRadio}
                            value="2tb"
                            name="radio-buttons-2"
                            inputProps={{ 'aria-label': 'TB' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-2'] === '2b'}
                            onChange={handleChangeRadio}
                            value="2b"
                            name="radio-buttons-2"
                            inputProps={{ 'aria-label': 'B' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-2'] === '2m'}
                            onChange={handleChangeRadio}
                            value="2m"
                            name="radio-buttons-2"
                            inputProps={{ 'aria-label': 'M' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-2'] === '2f'}
                            onChange={handleChangeRadio}
                            value="2f"
                            name="radio-buttons-2"
                            inputProps={{ 'aria-label': 'F' }}
                        />
                    </Grid>
                    <div style={{width: '100%'}}><FormHelperText sx={{color: 'red', textAlign: 'end', marginRight: 5}}>{error.coachSupport}</FormHelperText></div>
                    <TextField
                        value={commentValue['coachSupportComment']}
                        onChange={handleChangeComment}
                        id="coachSupportComment"
                        label="Commentaires eventuels"
                        multiline
                        rows={4}
                        sx={{m: 3, width: '100%'}}
                        error={!!error.coachSupportComment}
                        helperText={error.coachSupportComment}
                    />
                </Grid>
                <Grid container spacing={3} sx={{mt: 2, textAlign: 'left'}}>
                    <Grid item xs={12} sm={8}>
                        3. L'adaptation du.de la coach à ma problématique ?
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-3'] === '3tb'}
                            onChange={handleChangeRadio}
                            value="3tb"
                            name="radio-buttons-3"
                            inputProps={{ 'aria-label': 'TB' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-3'] === '3b'}
                            onChange={handleChangeRadio}
                            value="3b"
                            name="radio-buttons-3"
                            inputProps={{ 'aria-label': 'B' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-3'] === '3m'}
                            onChange={handleChangeRadio}
                            value="3m"
                            name="radio-buttons-3"
                            inputProps={{ 'aria-label': 'M' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-3'] === '3f'}
                            onChange={handleChangeRadio}
                            value="3f"
                            name="radio-buttons-3"
                            inputProps={{ 'aria-label': 'F' }}
                        />
                    </Grid>
                    <div style={{width: '100%'}}><FormHelperText sx={{color: 'red', textAlign: 'end', marginRight: 5}}>{error.coachAdaptation}</FormHelperText></div>
                    <TextField
                        value={commentValue['coachAdaptationComment']}
                        onChange={handleChangeComment}
                        id="coachAdaptationComment"
                        label="Commentaires eventuels"
                        multiline
                        rows={4}
                        sx={{m: 3, width: '100%'}}
                        error={!!error.coachAdaptationComment}
                        helperText={error.coachAdaptationComment}
                    />
                </Grid>
                <Grid container spacing={3} sx={{mt: 2, textAlign: 'left'}}>
                    <Grid item xs={12} sm={8}>
                        4. La durée et la fréquence des visios ?
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-4'] === '4tb'}
                            onChange={handleChangeRadio}
                            value="4tb"
                            name="radio-buttons-4"
                            inputProps={{ 'aria-label': 'TB' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-4'] === '4b'}
                            onChange={handleChangeRadio}
                            value="4b"
                            name="radio-buttons-4"
                            inputProps={{ 'aria-label': 'B' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-4'] === '4m'}
                            onChange={handleChangeRadio}
                            value="4m"
                            name="radio-buttons-4"
                            inputProps={{ 'aria-label': 'M' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-4'] === '4f'}
                            onChange={handleChangeRadio}
                            value="4f"
                            name="radio-buttons-4"
                            inputProps={{ 'aria-label': 'F' }}
                        />
                    </Grid>
                    <div style={{width: '100%'}}><FormHelperText sx={{color: 'red', textAlign: 'end', marginRight: 5}}>{error.videoFrequencyAndDuration}</FormHelperText></div>
                    <TextField
                        value={commentValue['videoFrequencyAndDurationComment']}
                        onChange={handleChangeComment}
                        id="videoFrequencyAndDurationComment"
                        label="Commentaires eventuels"
                        multiline
                        rows={4}
                        sx={{m: 3, width: '100%'}}
                        error={!!error.videoFrequencyAndDurationComment}
                        helperText={error.videoFrequencyAndDurationComment}
                    />
                </Grid>
                <Grid container spacing={3} sx={{mt: 2, textAlign: 'left'}}>
                    <Grid item xs={12} sm={8}>
                        5. La qualité de la plateforme Wellmonday ?
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-5'] === '5tb'}
                            onChange={handleChangeRadio}
                            value="5tb"
                            name="radio-buttons-5"
                            inputProps={{ 'aria-label': 'TB' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-5'] === '5b'}
                            onChange={handleChangeRadio}
                            value="5b"
                            name="radio-buttons-5"
                            inputProps={{ 'aria-label': 'B' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-5'] === '5m'}
                            onChange={handleChangeRadio}
                            value="5m"
                            name="radio-buttons-5"
                            inputProps={{ 'aria-label': 'M' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-5'] === '5f'}
                            onChange={handleChangeRadio}
                            value="5f"
                            name="radio-buttons-5"
                            inputProps={{ 'aria-label': 'F' }}
                        />
                    </Grid>
                    <div style={{width: '100%'}}><FormHelperText sx={{color: 'red', textAlign: 'end', marginRight: 5}}>{error.platformQuality}</FormHelperText></div>
                    <TextField
                        value={commentValue['platformQualityComment']}
                        onChange={handleChangeComment}
                        id="platformQualityComment"
                        label="Commentaires eventuels"
                        multiline
                        rows={4}
                        sx={{m: 3, width: '100%'}}
                        error={!!error.platformQualityComment}
                        helperText={error.platformQualityComment}
                    />
                </Grid>
                <div style={{textAlign: 'left', paddingRight: 23}}>
                    <Typography variant='body1'>6. Quels sont les éléments que vous avez apprécié dans ce coaching ?</Typography>
                    <TextField
                        value={commentValue['positiveElements']}
                        onChange={handleChangeComment}
                        id="positiveElements"
                        label="Commentaires eventuels"
                        multiline
                        rows={4}
                        sx={{mt: 3, mb: 3, width: '100%'}}
                        error={!!error.positiveElements}
                        helperText={error.positiveElements}
                    />
                    <Typography variant='body1'>7. Y a-t-il des points dont vous n'êtes pas satisfait.e ?</Typography>
                    <TextField
                        value={commentValue['negativeElements']}
                        onChange={handleChangeComment}
                        id="negativeElements"
                        label="Commentaires eventuels"
                        multiline
                        rows={4}
                        sx={{mt: 3, mb: 3, width: '100%'}}
                        error={!!error.negativeElements}
                        helperText={error.negativeElements}
                    />
                    <Typography variant='body1'>8. Y a-t-il des points que vous auriez préféré aborder ?</Typography>
                    <TextField
                        value={commentValue['missingElements']}
                        onChange={handleChangeComment}
                        id="missingElements"
                        label="Commentaires eventuels"
                        multiline
                        rows={4}
                        sx={{mt: 3, mb: 3, width: '100%'}}
                        error={!!error.missingElements}
                        helperText={error.missingElements}
                    />
                    <Typography variant='body1'>Libre expression</Typography>
                    <TextField
                        value={commentValue['freeComment']}
                        onChange={handleChangeComment}
                        id="freeComment"
                        label="Commentaires eventuels"
                        multiline
                        rows={4}
                        sx={{mt: 3, mb: 3, width: '100%'}}
                        error={!!error.freeComment}
                        helperText={error.freeComment}
                    />
                </div>
                <Grid container spacing={3} sx={{mt: 2, textAlign: 'left'}}>
                    <Grid item xs={12} sm={8}>
                    </Grid>
                    <Grid item xs={3} sm={1} sx={{ml: 0.5}}>
                        Oui
                    </Grid>
                    <Grid item xs={3} sm={1} sx={{ml: 0.5}}>
                        Non
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{mt: 2, textAlign: 'left'}}>
                    <Grid item xs={12} sm={8}>
                        9. Recommanderiez-vous Wellmonday ?
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-9'] === '9oui'}
                            onChange={handleChangeRadio}
                            value="9oui"
                            name="radio-buttons-9"
                            inputProps={{ 'aria-label': '9OUI' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-9'] === '9non'}
                            onChange={handleChangeRadio}
                            value="9non"
                            name="radio-buttons-9"
                            inputProps={{ 'aria-label': '9NON' }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{mt: 2, textAlign: 'left'}}>
                    <Grid item xs={12} sm={8}>
                        10. Le coaching a-t-il répondu à vos attentes ?
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-10'] === '10oui'}
                            onChange={handleChangeRadio}
                            value="10oui"
                            name="radio-buttons-10"
                            inputProps={{ 'aria-label': '10OUI' }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Radio
                            checked={selectedValue['radio-buttons-10'] === '10non'}
                            onChange={handleChangeRadio}
                            value="10non"
                            name="radio-buttons-10"
                            inputProps={{ 'aria-label': '10NON' }}
                        />
                    </Grid>
                </Grid>
                <Button variant='contained' onClick={handleSendEvaluation} sx={{mt: 3}}>Envoyer</Button>
            </Paper>
        </Zoom>
    )
}

function translateValue(value) {
    if (value) {
        const valueWithoutNumber = value.replace(/[0-9]/g, '')
        switch (valueWithoutNumber) {
            case 'tb':
                return "Très bien"
            case 'm':
                return 'Moyen'
            case 'f':
                return 'Faible'
            case 'b':
                return 'Bien'
            case 'oui':
                return true
            case 'non':
                return false
            default:
                return null
        }
    }
    return ''
}