import React from 'react'
import { Zoom, Paper, Typography, Grid, TextField, Button, Badge, IconButton, Autocomplete } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Avatar } from '@mui/material'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useEffect } from 'react';
import { TransitionLeft } from '../../helper/utils';
import { apiFetch } from '../../service/security/apiFetch';
import PhotoPopup from './PhotoPopup';
import { handleFormError } from '../../service/security/error';

export default function CoachProfil({ zoom, user, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const CssIconButton = styled(IconButton)({
        backgroundColor: '#ECA914',
        color: 'white',
        "&:hover": { 
            backgroundColor: "white",
            color: '#ECA914'
        },
    });
    const [softSkills, setSoftSkills] = useState([])
    const [value, setValue] = useState([])
    const [openPhotoPopup, setOpenPhotoPopup] = useState(false)
    const [error, setError] = useState({})
    const [disable, setDisable] = useState(true)
    const [photo, setPhoto] = useState(false)
    let history = useHistory()

    useEffect(() => {
        (async () => {
            const fetchSoftSkills = await apiFetch('/soft_skills')
            if (fetchSoftSkills && fetchSoftSkills['hydra:member']) {
                setSoftSkills(fetchSoftSkills['hydra:member'])
                setValue(user.coach.softSkills)
            } else {
                setTransition(() => TransitionLeft)
                setText('Erreur lors du chargement des données')
                setSeverity('error')
                setOpenSnackbar(true)
                history.goBack()
            }
        })()
    }, [setSoftSkills, setTransition, setText, setSeverity, setOpenSnackbar, history, setValue, user])
    
    const handleChangePassword = () => {
        history.push('/change-password')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setDisable(true)
        let data = new FormData(e.target)
        const email = data.get('email')
        const mobile = data.get('mobile')

        const updateProfile = await apiFetch(`${user.coach['@id']}`, {
            method: 'PUT',
            body: JSON.stringify({user: {'@id': user['@id'], email: email, mobile: mobile}, softSkills: formatSoftSkills(value)})
        })
        if (updateProfile && updateProfile.uuid) {
            setOpenPhotoPopup(false)
            setSeverity('success')
            setText('Votre profil a bien été modifié !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        } else {
            const formError = handleFormError(updateProfile, ['user.email', 'user.mobile'])
            setError(formError)
            setSeverity('error')
            setText('Erreur lors de la modification du profil !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDisable(false)
    }

    const handlePhotoPopup = () => {
        setDisable(false)
        setOpenPhotoPopup(true)
    }

    const handleChoose = async () => {
        setDisable(true)
        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData() 
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/photo_objects', {
                method: 'POST',
                body: dataPhoto,
            }, true)
        }

        if (createPhoto && !createPhoto['hydra:description']) {
            const updatePhoto = await apiFetch(`${user.coach['@id']}`, {
                method: 'PUT',
                body: JSON.stringify({photo: createPhoto['@id']})
            })
            if (updatePhoto && updatePhoto.uuid) {
                setOpenPhotoPopup(false)
                setSeverity('success')
                setText('Votre photo a bien été modifiée !')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                window.location.reload()
            } else {
                setSeverity('error')
                setText('Erreur lors de la modification de la photo !')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
        } else {
            setSeverity('error')
            setText('Erreur lors de la modification de la photo !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDisable(false)

    }

    const formatSoftSkills = (array) => {
        let a = []
        array.map((item) => {
            return a.push(item['@id'])
        })
        return a
    }

    const handleChange = () => {
        setDisable(false)
    }

    return (
        <Zoom in={zoom} timeout={700}>
            <Paper elevation={16} sx={{padding: 10}}>
                <Typography variant='h5' color='secondary' style={{fontWeight: 'bold'}}>Profil</Typography>
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                        <CssIconButton onClick={handlePhotoPopup} ><ModeEditIcon /></CssIconButton>
                    }
                >
                    <Avatar src={user.coach.photo.contentUrl} sx={{ml: 5, mt: 5, width: 110, height: 110}} />
                </Badge>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} sx={{ml: 2, mb: 2, mt: 0.5}}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='h6' color='secondary' sx={{mt: 3}}><span style={{color: 'gray'}}>Nom : </span>{user.lastName}</Typography>
                            <Typography variant='h6' color='secondary' sx={{mt: 4}}><span style={{color: 'gray'}}>Prénom : </span>{user.firstName}</Typography>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={softSkills}
                                getOptionLabel={(option) => option.name}
                                defaultValue={user.coach.softSkills}
                                onChange={(event, newValue) => {
                                    setDisable(false)
                                    setValue(newValue);
                                }}
                                filterSelectedOptions
                                sx={{mt: 4, width: 300}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Soft Skills"
                                        placeholder="Choisissez"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField onChange={handleChange} helperText={error.email} error={!!error.email} color='secondary' label='Email' id='email' name='email' sx={{width: 300}} defaultValue={user.email} /><br />
                            <TextField onChange={handleChange} helperText={error.mobile} error={!!error.mobile} color='secondary' label='Mobile' id='mobile' name='mobile' sx={{mt: 3, width: 300}} type='tel' defaultValue={user.mobile} /><br />
                            <Button variant='contained' color='primary' sx={{mt: 2}} type='submit' disabled={disable}>Enregistrer</Button>
                        </Grid>
                    </Grid>
                    <Button variant='contained' color='primary' sx={{mt: 3, ml: 5}} onClick={handleChangePassword}>Changer de mot de passe</Button>
                </form>
                <PhotoPopup disable={disable} open={openPhotoPopup} handleChoose={handleChoose} handleClose={() => setOpenPhotoPopup(false)} photo={photo} setPhoto={setPhoto} />
            </Paper>
        </Zoom>
    )
}