import React, { useState, useEffect } from 'react'
import { apiFetch } from '../service/security/apiFetch';
import { TransitionLeft } from '../helper/utils';
import Room from './VisioItem/Room';
import { useHistory, useParams } from 'react-router-dom';
import CloseVisioPopup from '../components/CloseVisioPopup';
import EndedVisioPopup from './VisioItem/EndedVisioPopup';
import { hasRoles } from '../service/security/hasRoles';

let tempToken = null
let tempChatToken = null
export default function Visio({ setTransition, setSeverity, setText, setOpenSnackbar, boxRef, mainRef, user }) {
    const [roomName, setRoomName] = useState('');
    const [token, setToken] = useState(null);
    const [chatToken, setChatToken] = useState(null);
    const [openClosePopup, setOpenClosePopup] = useState(false);
    const [openEndedVisioPopup, setOpenEndedVisioPopup] = useState(false);
    let history = useHistory()
    const { id } = useParams()
    document.title = 'Wellmonday - Visio'

    useEffect(() => {
        apiFetch(`/twilio/video/${id}/join`)
            .then(res => {
                if (res && res.token) {
                    setToken(res.token);
                    setChatToken(res.chatToken)
                    tempToken = res.token
                    tempChatToken = res.chatToken
                    setRoomName(res.roomName)
                    boxRef.current.style.display = 'none'
                    mainRef.current.style.padding = '0px'
                } else {
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setText('Désolé, une erreur s\'est produite, veuillez réessayer')
                    setOpenSnackbar(true)
                }
            })
        return () => (history.goBack())
    }, [setToken, setRoomName, setOpenSnackbar, setText, setSeverity, setTransition, id, history, boxRef, mainRef]);

    const handleLogout = async () => {
        const endVisio = await apiFetch(`/twilio/video/${id}/close`)
        if (endVisio.success) {
            setText(endVisio.message)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            if (hasRoles() === 'coach' || (hasRoles() === 'student' && endVisio.nextSeanceAvailable)) {
                setOpenEndedVisioPopup(endVisio)
            } else {
                handleClose(false, {isPopup: true})
            }
        }
        setToken(null);
        tempToken = null
        tempChatToken = null
    };

    const handleClose = (e, params) => {
        if (params && params.isEndOfVisio) {
            handleLogout()
        } else if (params && params.isPopup) {
            setTimeout(() =>{
                window.close()
            }, 1000)
        } else {
            setToken(tempToken)
            setChatToken(tempChatToken)
        }
        setOpenClosePopup(false)
    }

    return (
        <>
            {token && chatToken && <Room user={user} chatToken={chatToken} setToken={setToken} setOpenClosePopup={setOpenClosePopup} handleClose={handleClose} setText={setText} setSeverity={setSeverity} setTransition={setTransition} setOpenSnackbar={setOpenSnackbar} roomName={roomName} token={token} handleLogout={handleLogout} />}
            <CloseVisioPopup handleClose={handleClose} open={openClosePopup} />
            <EndedVisioPopup open={openEndedVisioPopup} handleClose={handleClose} setText={setText} setSeverity={setSeverity} setTransition={setTransition} setOpenSnackbar={setOpenSnackbar} />
        </>
    )
}
