import React,{ useState } from 'react'
import { Zoom, Paper, TextField, Button, Typography, InputAdornment, IconButton } from '@mui/material'
import { Divider } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { progressBarWidth, TransitionLeft } from '../../helper/utils'
import { ButtonGroup } from '@mui/material'
import { useHistory } from 'react-router-dom'
import ProgressBar from '../../components/ProgressBar'
import LoadingButton from '@mui/lab/LoadingButton';
import { apiFetch } from '../../service/security/apiFetch'
import { handleFormError } from '../../service/security/error'

export default function UpdatePassword({ zoom, user, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const [error, setError] = useState({})
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [style, setStyle] = useState(false)
    const [loading, setLoading] = useState(false)
    document.title = 'Wellmonday - Changer de mot de passe'
    
    let history = useHistory()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError({})

        const data = new FormData(e.target)
        const oldPassword = data.get('oldPassword')
        const password = data.get('password')
        const confirmNewPassword = data.get('confirmNewPassword')

        if (!oldPassword || !password) {
            if (!oldPassword) setError({...error, password: 'Veuillez renseigner ce champ'})
            if (!password) setError({...error, password: 'Veuillez renseigner ce champ'})
            setLoading(false)
            return
        }

        if (password !== confirmNewPassword) {
            setLoading(false)
            return setError({confirmNewPassword: 'Ce mot de pass n\'est pas identique au nouveau mot de pass'})
        }

        const dataObject = {
            oldPassword: oldPassword,
            password: password,
        }  

        const changePassword = await apiFetch(`${user['@id']}/update/password`, {
            method: 'PUT',
            body: JSON.stringify(dataObject)
        })

        if (changePassword['hydra:description']) {
            const formError = handleFormError(changePassword, ['password'])
            setError(formError)
            setText(changePassword.error)
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        } else {
            setText('Le mot de passe a bien été modifié !')
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
            setTimeout(() => {
                history.push('/profil')
            }, 2000)
        }
        setLoading(false)
    }    

    const handleClickOldPassword = () => {
        setShowOldPassword(!showOldPassword)
    }

    const handleClickNewPassword = () => {
        setShowNewPassword(!showNewPassword)
    }

    const handleClickConfirmNewPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const handleChangeProgressBar = (e) => {
        progressBarWidth(e, setStyle)
    }

    const handleBack = () => {
        history.goBack()
    }

    return (
        <Zoom in={zoom} timeout={700}>
            <Paper elevation={16} sx={{padding: '20px 40px', textAlign: 'center'}}>
                <form noValidate onSubmit={handleSubmit} autoComplete="off">
                        <Typography variant='h5' color={'secondary'} style={{textAlign: 'left'}}>Modification du mot de passe</Typography>
                        <Divider style={{marginTop: 20}} />
                        <TextField 
                            id="oldPassword" 
                            name="oldPassword" 
                            label="Mot de passe actuel"
                            type={showOldPassword ? "text" : "password"}
                            sx={{marginTop: 5, width: {xl: 400, lg: 400, md: 400, sm: 200, xs: 300}}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickOldPassword}
                                        >
                                            {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required
                            autoComplete="on"
                            error={!!error.oldPassword} helperText={error.oldPassword}
                        /><br />
                        <TextField 
                            id="password" 
                            name="password" 
                            label="Nouveau mot de passe"
                            sx={{marginTop: 5, width: {xl: 400, lg: 400, md: 400, sm: 200, xs: 300}}}
                            type={showNewPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickNewPassword}
                                        >
                                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required
                            autoComplete="on"
                            onChange={handleChangeProgressBar}
                            error={!!error.password} helperText={error.password}
                        />
                        <ProgressBar style={style} />
                        <TextField 
                            id="confirmNewPassword" 
                            name="confirmNewPassword" 
                            label="Confirmer le nouveau mot de passe"
                            type={showConfirmPassword ? "text" : "password"}
                            sx={{marginTop: 5, width: {xl: 400, lg: 400, md: 400, sm: 200, xs: 300}}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickConfirmNewPassword}
                                        >
                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required
                            autoComplete="on"
                            error={!!error.confirmNewPassword}
                            helperText={error.confirmNewPassword}
                        /><br />
                        <ButtonGroup style={{marginTop: 40}} color={'primary'} variant="contained" aria-label="contained primary button group">
                            <LoadingButton
                                type='submit'
                                loading={loading}
                                color={'primary'}
                                variant="contained"
                            >
                                Enregistrer
                            </LoadingButton>
                            <Button onClick={handleBack} disabled={loading}>
                                Retour
                            </Button>
                        </ButtonGroup>
                </form>
            </Paper>
        </Zoom>
    )
}