import React, { useEffect, useState } from 'react'
import { getArrayOfSeanceEnded, shouldShowChangeCoachButton, shouldShowCloseButton, shouldShowContractsButton, shouldShowExtensionButton, shouldShowSignedButton, shouldShowVisionButton, TransitionLeft, translateStatus } from '../helper/utils'
import { apiFetch } from '../service/security/apiFetch'
import { useHistory, useParams } from 'react-router-dom';
import {
    Zoom,
    Paper,
    Typography,
    Grid,
    MobileStepper,
    Button,
    ButtonGroup,
    Toolbar,
    LinearProgress,
} from '@mui/material/';
import moment from 'moment'
import { hasRoles } from '../service/security/hasRoles';
import AddCoachingPopup from './CoachingItem/AddCoachingPopup';
import { useStudents } from '../hook/students';
import { handleFormError } from '../service/security/error';
import Tooltip from '@mui/material/Tooltip';
import { handleContractPopup, handleOpenConfirmationPopup, handleOpenManageExtensionPopup, handleSignePopup } from '../helper/CoachingActions';

export default function Coachings({ zoom, setOpenSnackbar, setText, setTransition, setSeverity, setOpenExtensionPopup, coachings, setCoachings, setOpenContractPopup, setOpenConfirmationPopup, setOpenBackdrop, setOpenSignePopup, setSeancesSuggested, setOpenManageExtensionPopup }) {
    const [openAddPopup, setOpenAddPopup] = useState(false)
    const [coachs, setCoachs] = useState(false)
    const [disable, setDisable] = useState(false)
    const [student, setStudent] = useState('')
    const [coach, setCoach] = useState('')
    const [error, setError] = useState({})
    const params = useParams()
    let history = useHistory()
    document.title = 'Wellmonday - Coachings'

    const {
        students,
        fetchStudents,
        createStudents
    } = useStudents()

    useEffect(() => {
        (async () => {
            let fetchCoachings = null
            if (params.id && !coachings) {
                fetchCoachings = await apiFetch(`/coachings?session.uuid=${params.id}`)
            } else if (hasRoles() !== 'admin' && !coachings) {
                fetchCoachings = await apiFetch('/coachings')
            }

            if (fetchCoachings && fetchCoachings['hydra:member']) {
                setCoachings(fetchCoachings['hydra:member'])
            } else if (!coachings) {
                setTransition(() => TransitionLeft)
                setText('Erreur lors du chargement des données')
                setSeverity('error')
                setOpenSnackbar(true)
                history.push('/')
            }
        })()
    }, [setText, setTransition, setSeverity, setOpenSnackbar, history, params, coachings, setCoachings])

    useEffect(() => {
        (async () => {
            if (hasRoles() === 'admin') {
                const studentsFetch = await fetchStudents(`/students?company.uuid=${params.company}`)
                if (studentsFetch && studentsFetch['hydra:description']) {
                    setTransition(() => TransitionLeft)
                    setText('Erreur lors du chargement des données')
                    setSeverity('error')
                    setOpenSnackbar(true)
                    history.goBack()
                }
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setText, setTransition, setSeverity, setOpenSnackbar, history])

    useEffect(() => {
        (async () => {
            if (hasRoles() === 'admin') {
                const coachsFetch = await apiFetch('/coaches')
                if (coachsFetch && coachsFetch['hydra:member']) {
                    setCoachs(coachsFetch['hydra:member'])
                } else {
                    setTransition(() => TransitionLeft)
                    setText('Erreur lors du chargement des données')
                    setSeverity('error')
                    setOpenSnackbar(true)
                    history.goBack()
                }
            }
        })()
    }, [setText, setTransition, setSeverity, setOpenSnackbar, history])

    const handleClick = (id) => {
        history.push(`/coaching/${id}/information`)
    }


    const handleClose = () => {
        setOpenAddPopup(false)
    }

    const handleAddCoaching = async () => {
        setError({})
        if (!student) return setError({student: 'Veuillez choisir un salarié !'})
        setDisable(true)

        let body = {
            session: `/coaching_sessions/${params.id}`,
            student: student['@id'],
        }
        if (coach && coach !== 'Pas de coach recommandé') {
            body.promotedCoach = coach
        }

        const addCoaching = await apiFetch('/coachings', {
            method: 'POST',
            body: JSON.stringify(body)
        })
        if (addCoaching.uuid) {
            const fetchCoachings = await apiFetch(`/coachings?session.uuid=${params.id}`)
            if (fetchCoachings && fetchCoachings['hydra:member']) {
                setCoachings(fetchCoachings['hydra:member'])
                setTransition(() => TransitionLeft)
                setText('le coaching a été créé')
                setSeverity('success')
                setOpenSnackbar(true)
                setCoach('')
                setStudent('')
            } else {
                setTransition(() => TransitionLeft)
                setText('Erreur lors du chargement des données')
                setSeverity('error')
                setOpenSnackbar(true)
            }
        } else {
            const formError = handleFormError(addCoaching, ['student'])
            setError(formError)
            setTransition(() => TransitionLeft)
            setText('Erreur lors de la création du coaching, veuillez réessayer')
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setDisable(false)
    }

    const handleVisio = (row) => {
        window.open(row.visioUrl, "_blank")
    }

    const handleBack = () => {
        setCoachings(false)
        history.goBack()
    }

    return (
        <div style={{marginTop: 50}}>
            {hasRoles() === 'admin' && <Button sx={{ml: 3}} onClick={handleBack} variant='contained' color='primary'>Retour</Button>}
            <Toolbar>
                <Typography variant='h5' sx={{fontWeight: 'bold', flex: '1 1 100%'}} color='secondary'>{hasRoles() === 'admin' ? 'COACHINGS' : hasRoles() === 'manager' ? 'SUIVI DES COACHINGS' : 'MES COACHINGS'}</Typography>
                {hasRoles() === "admin" && <Button variant='contained' color='primary' onClick={() => setOpenAddPopup(true)}>Ajouter un coaching</Button>}
            </Toolbar>
            <AddCoachingPopup disable={disable} company={params.company} coachs={coachs} error={error} createStudents={createStudents} open={openAddPopup} handleClose={handleClose} handleAdd={handleAddCoaching} students={students} student={student} setStudent={setStudent} coach={coach} setCoach={setCoach} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} />
            {coachings ? coachings.length ? coachings.map((coaching, index) => {
                return (
                    <Zoom in={zoom} timeout={700} key={index}  style={{ transitionDelay: index * 100 + 200 + 'ms' }}>
                        <Paper elevation={16} sx={{mt: 3, padding: 4}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant='h6' color='primary'>{coaching.student && coaching.student.user ? coaching.student.user.firstName : coaching.coach && coaching.coach.user.firstName} {coaching.student.user ? coaching.student.user.lastName : coaching.coach && coaching.coach.user.lastName}</Typography>
                                    {coaching.seances && <div style={{marginTop: 10, color: '#707070'}}>
                                        <span>Prochaine séance : </span><br />
                                        <span>Début : </span><br />
                                        {hasRoles() === 'admin' && <span>Statut : </span>}
                                    </div>}
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant='h6'>{coaching.student.company && coaching.student.company.name.toUpperCase()}</Typography>
                                    <div style={{marginTop: 10}}>
                                        <Typography variant='body1'>{coaching.nextSeance && coaching.nextSeance.length ? moment(coaching.nextSeance.availability && coaching.nextSeance.availability.startAt).format('DD MMMM YYYY à HH:mm') : 'Pas de prochaine séance'}</Typography>
                                        <Typography variant='body1'>{moment(coaching.createdAt).format('DD MMMM yyyy')}</Typography>
                                        {hasRoles() === 'admin' && <Typography variant='body1'>{translateStatus(coaching.status)}</Typography>}
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant='h6'>{(getArrayOfSeanceEnded(coaching.seances) && getArrayOfSeanceEnded(coaching.seances).length) || 0 } séance(s) réalisée sur {coaching.nbSeances}</Typography>
                                    <ButtonGroup variant='contained' color='secondary' sx={{mt: 4}}>
                                        <Button variant='contained' color='primary' onClick={() => handleClick(coaching.uuid)}>Voir le coaching</Button>
                                        {shouldShowVisionButton(coaching) && <Tooltip arrow title={!coaching.nextSeance.visioUrl ? 'Vous pouvez rejoindre la visio à partir de 5 min avant le début de la séance' : ''}><span><Button disabled={!coaching.nextSeance.visioUrl} variant='contained' color='secondary' sx={{color: 'white'}} onClick={() => handleVisio(coaching.nextSeance)}>Rejoindre la Visio</Button></span></Tooltip>}
                                    </ButtonGroup>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <MobileStepper
                                        variant="progress"
                                        steps={coaching.nbSeances + 1}
                                        position="static"
                                        activeStep={(getArrayOfSeanceEnded(coaching.seances) && getArrayOfSeanceEnded(coaching.seances).length) || 0}
                                        sx={{ maxWidth: 300, mt: 1, flexGrow: 1, backgroundColor: '#fff' }}
                                        LinearProgressProps={{style: {width: '100%', height: '7px'}}}
                                    />
                                    {coaching.seances && <>
                                        <ButtonGroup variant='contained' color='secondary' sx={{color: 'white', mt: 4}}>
                                            {shouldShowCloseButton(coaching) && <Button disabled={disable} sx={{color: 'white'}} onClick={() => setOpenExtensionPopup({coaching})}>
                                                Prolonger
                                            </Button>}
                                            {shouldShowCloseButton(coaching) && <Button disabled={disable} sx={{color: 'white'}} onClick={() => handleOpenConfirmationPopup({coaching, setOpenConfirmationPopup})}>
                                                Clôturer
                                            </Button>}
                                            {shouldShowExtensionButton(coaching) && <Button disabled={disable} variant='contained' color='secondary' sx={{color: 'white'}} onClick={() => handleOpenManageExtensionPopup(coaching, setSeancesSuggested, setOpenManageExtensionPopup, params)}>Gérer la demande de prolongation</Button>}
                                        </ButtonGroup>
                                        <ButtonGroup variant='contained' color='secondary' sx={{color: 'white', mt: 4}}>
                                            {shouldShowSignedButton(coaching) ? <Button disabled={disable} variant='contained' color='secondary' sx={{color: 'white'}} onClick={() => handleSignePopup({coaching, setOpenBackdrop, setOpenSignePopup, setTransition, setText, setSeverity, setOpenSnackbar})}>Signer le contrat de coaching</Button> :
                                            shouldShowContractsButton(coaching) && <Button disabled={disable} variant='contained' color='secondary' sx={{color: 'white'}} onClick={() => handleContractPopup({coaching, setOpenContractPopup})}>Générer le contrat de coaching</Button>}
                                            {shouldShowChangeCoachButton(coaching) && <Button disabled={disable} variant='contained' color='secondary' sx={{color: 'white'}} onClick={() => handleOpenConfirmationPopup({coaching, setOpenConfirmationPopup})}>Changer de coach</Button>}
                                        </ButtonGroup>
                                    </>}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Zoom>
                )
            }) : 
            <Zoom in={zoom} timeout={700} style={{ transitionDelay: 100 + 200 + 'ms' }}>
                <Paper elevation={16} sx={{mt: 3, padding: 4}}>
                    <Typography>Pas de coaching pour le moment...</Typography>
                </Paper>
            </Zoom>
            : <LinearProgress sx={{mt: 4}} />}
        </div>
    )
}
