import React, { useEffect, useState } from 'react'
import {
    Box,
    CssBaseline,
    Drawer,
    Avatar,
    Toolbar,
    Typography,
    AppBar,
    IconButton,
    useMediaQuery,
    CircularProgress,
    Backdrop,
} from '@mui/material/';
import Navigation from './MainMenuItem/Navigation';
import { apiFetch } from '../service/security/apiFetch';
import AuthService from '../service/security/handleTokenProvider'
import { useHistory } from 'react-router-dom';
import logo from '../img/logo.png'
import whiteLogo from '../img/logo_white.png'
import yellowLogo from '../img/logo_yellow.png'
import menuLogo from '../img/menu-logo.png'
import MenuIcon from '@mui/icons-material/Menu';
import { shouldShowChangeCoachButton } from '../helper/utils';
import Error from './Error';
import { Route } from 'react-router-dom'
import { routes } from '../service/routes/routes';
import Dashboard from './Dashboard';
import InitializingCoaching from './InitializingCoaching';
import AlertSnackbar from '../components/AlertSnackbar'
import MakeAppointment from './MakeAppointment';
import Planning from './Planning';
import Coachings from './Coachings';
import CoachingDetail from './CoachingItem/CoachingDetail';
import Toolbox from './Toolbox';
import Messages from './Messages';
import { hasRoles } from '../service/security/hasRoles';
import { AdminRoute } from '../service/routes/AdminRoute';
import Companies from './companies';
import Coachs from './Coachs';
import Parameter from './Parameter';
import { AdminAndCoachRoute } from '../service/routes/AdminAndCoachRoute';
import Profil from './Profil';
import Ressources from './Ressources';
import { ManagerRoute } from '../service/routes/ManagerRoute';
import UpdatePassword from './ProfilItem/UpdatePassword';
import CoachingSessions from './CoachingSessions';
import Visio from './Visio';
import { useRef } from 'react';
import ManageCompanies from './CompaniesItem/ManageCompanies';
import { StudentRoute } from '../service/routes/StudentRoute';
import CoachingEvaluation from './CoachingEvaluation';
import ContractPopup from './CoachingItem/ContractPopup';
import SignePopup from '../components/SignePopup';
import ExtensionPopup from './CoachingItem/ExtensionPopup';
import ManageExtensionPopup from './CoachingItem/ManageExtensionPopup';
import DeletedConfirmationPopup from '../components/DeletedConfirmationPopup';
import { handleAcceptExtension, handleAddExtension, handleAddObjectives, handleChangeCoach, handleCloseCoaching, handleCloseConfirmationPopup, handleCloseSignePopup, handleDeclineExtension } from '../helper/CoachingActions';
import FormDownload from './HomeItem/FormDownload';
import { Redirect } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import MobileAlert from './MainMenuItem/MobileAlert';
import BLogManagement from './Content/BlogManagement';
import UsersPc from './UsersPc';

const drawerWidth = 350

export default function MainMenu(props) {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [mobilePopupOpen, setMobilePopupOpen] = useState(false);
    const [dashboardData, setDashboardData] = useState(false)
    const [zoom, setZoom] = useState(true);
    const [user, setUser] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openContractPopup, setOpenContractPopup] = useState(false)
    const [openSignePopup, setOpenSignePopup] = useState(false)
    const [openExtensionPopup, setOpenExtensionPopup] = useState(false)
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false)
    const [openManageExtensionPopup, setOpenManageExtensionPopup] = useState(false)
    const [disable, setDisable] = useState(false)
    const [coachings, setCoachings] = useState(false)
    const [error, setError] = useState({})
    const [seancesSuggested, setSeancesSuggested] = useState('')
    const [reason, setReason] = useState('')
    const [content, setContent] = useState('')
    const [transition, setTransition] = useState(false)
    const [severity, setSeverity] = useState(false)
    const [text, setText] = useState(false)
    const boxRef = useRef()
    const toolbarRef = useRef()
    const mainRef = useRef()
    const mobileResponsive = useMediaQuery('(max-width:900px)');
    let history = useHistory()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
        setMobilePopupOpen(!mobilePopupOpen);
    };

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    useEffect(() => {
        (async () => {
            const fetchUser = await apiFetch('/users/me')
            if (fetchUser && fetchUser['@id']) {
                setUser(fetchUser)
            }
        })()
    }, [history])
  
    const handleDisconnect = () => {
        AuthService.logout()
        history.push('/')
        window.location.reload()
    }

    const handleRestoreUser = async () => {
        localStorage.setItem('REACT_TOKEN_AUTH', localStorage.getItem('REACT_TOKEN_AUTH_ORIGIN'))
        localStorage.setItem('REACT_ROLE', localStorage.getItem('REACT_ROLE_ORIGIN'))
        localStorage.removeItem('REACT_TOKEN_AUTH_ORIGIN')
        localStorage.removeItem('REACT_ROLE_ORIGIN')
        history.push('/dashboard')
        const fetchUser = await apiFetch('/users/me')
        if (fetchUser && fetchUser['@id']) {
            setUser(fetchUser)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {mobileResponsive && <AppBar
                position="fixed"
                sx={{
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    ml: { md: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>}
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 }}}
                aria-label="menu"
                ref={boxRef} 
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: user.student ? '#ECA914 0% 0% no-repeat padding-box' : user.manager ? '#7f7f7f 0% 0% no-repeat padding-box' : '#01304A 0% 0% no-repeat padding-box' },
                    }}
                >
                    <Navigation handleRestoreUser={handleRestoreUser} user={user} setZoom={setZoom} handleDisconnect={handleDisconnect} handleDrawerToggle={handleDrawerToggle} />
                </Drawer>
                {user && <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: user.student ? '#ECA914 0% 0% no-repeat padding-box' : user.manager ? '#7f7f7f 0% 0% no-repeat padding-box' : '#01304A 0% 0% no-repeat padding-box' },
                    }}
                    open
                >
                    <div style={{textAlign: 'center', margin: '10px auto'}}>
                        <img src={hasRoles() === 'admin' || hasRoles() === 'coach' ? yellowLogo : hasRoles() === 'student' ? whiteLogo : logo} alt='logo' width='200px' style={{marginTop: 10}} />
                    </div>
                    <div style={{margin: '20px auto'}}>
                        {user && <Avatar alt="Student" style={{width: 70, height: 70, margin: 'auto'}} src={user.student ? user.student.photo && user.student.photo.contentUrl : user.coach && user.coach.photo && user.coach.photo.contentUrl}/>}
                        <Typography sx={{color: '#FFF', marginTop: 1}} variant={'h6'}>
                            {user && `${user.firstName} ${user.lastName}`}
                        </Typography>
                        <Typography sx={{color: '#FFF'}} variant={'body1'}>
                            {user && `${user.email}`}
                        </Typography>
                        <Typography sx={{color: '#FFF'}} variant={'body1'}>
                            {user && user.student ? `${user.student.mobile || ''}` : user.coach && `${user.coach.mobile || ''}`}
                        </Typography>
                        <div style={{borderBottom: '3px solid white', marginTop: 20, marginBottom: 20}}></div>
                        <Navigation handleRestoreUser={handleRestoreUser} setZoom={setZoom} handleDisconnect={handleDisconnect} user={user} />
                    </div>
                    {hasRoles() !== 'admin' && <img src={menuLogo} alt='circle logo' width='170px' style={{textAlign: 'center', margin: '0px auto'}} />}
                </Drawer>}
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                ref={mainRef}
            >
                <Backdrop style={{zIndex: 999999}} open={openBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {mobileResponsive && <Toolbar />}
                <MobileAlert open={isMobile} setOpen={setWidth} />
                <AlertSnackbar open={openSnackbar} setOpen={setOpenSnackbar} transition={transition} message={text} severity={severity} />
                <Switch>
                    <Route path={[routes.dashboard, routes.home]} exact><Dashboard setOpenConfirmationPopup={setOpenConfirmationPopup} setOpenContractPopup={setOpenContractPopup} disable={disable} setOpenExtensionPopup={setOpenExtensionPopup} user={user} setOpenSignePopup={setOpenSignePopup} coachings={coachings} setCoachings={setCoachings} setOpenBackdrop={setOpenBackdrop} dashboardData={dashboardData} setDashboardData={setDashboardData} zoom={zoom} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} /></Route>
                    <Route path={routes.initializing} exact><InitializingCoaching user={user} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} /></Route>
                    <Route path={routes.appointment} exact><MakeAppointment dashboardData={dashboardData} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} /></Route>
                    <Route path={routes.visio} exact ><Visio user={user} mainRef={mainRef} toolbarRef={toolbarRef} boxRef={boxRef} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} /></Route>
                    <AdminAndCoachRoute path={routes.planning} exact component={Planning} dashboardData={dashboardData} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />
                    <AdminRoute path={routes.companies} exact component={Companies} setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />
                    <AdminRoute path={routes.manageCompanies} exact component={ManageCompanies} setUser={setUser} setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />
                    <AdminRoute path={routes.coachs} exact component={Coachs} setUser={setUser} setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />
                    <AdminRoute path={routes.parameter} exact component={Parameter} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />
                    <AdminRoute path={routes.sessions} exact component={CoachingSessions} setCoachings={setCoachings} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />
                    <AdminRoute path={routes.coachingId} exact component={Coachings} setOpenManageExtensionPopup={setOpenManageExtensionPopup} setSeancesSuggested={setSeancesSuggested} coachings={coachings} setCoachings={setCoachings} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />
                    <AdminRoute path={routes.manageContent} exact component={BLogManagement} setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />
                    <AdminRoute path={routes.manageUsers} exact component={UsersPc} setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />
                    <Route path={routes.coaching} exact ><Coachings setOpenExtensionPopup={setOpenExtensionPopup} setOpenSignePopup={setOpenSignePopup} setOpenConfirmationPopup={setOpenConfirmationPopup} setOpenContractPopup={setOpenContractPopup} setCoachings={setCoachings} coachings={coachings} setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} /></Route>
                    <Route path={routes.informationCoachings} exact ><CoachingDetail setOpenExtensionPopup={setOpenExtensionPopup} setOpenConfirmationPopup={setOpenConfirmationPopup} setSeancesSuggested={setSeancesSuggested} setOpenManageExtensionPopup={setOpenManageExtensionPopup} setOpenSignePopup={setOpenSignePopup} setOpenContractPopup={setOpenContractPopup} setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} /></Route>
                    <Route path={routes.toolbox} exact ><Toolbox setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} /></Route>
                    <Route path={routes.messages} exact ><Messages user={user} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} /></Route>
                    <Route path={routes.profil} exact ><Profil user={user} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} /></Route>
                    <Route path={routes.updatePassword} exact ><UpdatePassword user={user} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} /></Route>
                    <ManagerRoute path={routes.ressources} exact component={Ressources} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />
                    <StudentRoute path={routes.coachingEvaluation} exact component={CoachingEvaluation} user={user} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />
                    <Route path={routes.download} exact><FormDownload zoom={zoom} /></Route>
                    <Route path={'/error'}><Error /></Route>
                    <Redirect exact to={routes.error}/>
                </Switch>
                <ContractPopup
                    error={error}
                    content={content}
                    setContent={setContent}
                    disable={disable}
                    open={openContractPopup}
                    handleClose={() => setOpenContractPopup(false)}
                    handleAdd={(e) => handleAddObjectives(e, {setCoachings, history, setContent, setOpenContractPopup, setTransition, setText, setSeverity, setOpenSnackbar, setDisable, setError, openContractPopup, content, setDashboardData})}
                />
                <SignePopup 
                    disable={disable}
                    open={openSignePopup}
                    handleClose={() => handleCloseSignePopup({ setCoachings, setTransition, setText, setSeverity, setOpenSnackbar, setOpenSignePopup})}
                />
                <ExtensionPopup 
                    error={error}
                    reason={reason}
                    setReason={setReason}
                    seances={seancesSuggested}
                    setSeances={setSeancesSuggested}
                    disable={disable} open={openExtensionPopup}
                    handleClose={() => setOpenExtensionPopup(false)}
                    handleAdd={() => handleAddExtension({openExtensionPopup, setOpenExtensionPopup, setCoachings, setError, seancesSuggested, reason, setTransition, setText, setSeverity, setOpenSnackbar, setDisable, setDashboardData })}
                />
                <ManageExtensionPopup
                    error={error}
                    disable={disable}
                    open={openManageExtensionPopup}
                    seances={seancesSuggested}
                    setSeances={setSeancesSuggested}
                    setOpen={setOpenManageExtensionPopup}
                    handleClose={() => handleDeclineExtension({ setOpenManageExtensionPopup, setCoachings, setTransition, setText, setSeverity, setOpenSnackbar, setDisable, openManageExtensionPopup})}
                    handleAdd={() => handleAcceptExtension({ seancesSuggested, setOpenManageExtensionPopup, setCoachings, setTransition, setText, setSeverity, setOpenSnackbar, setDisable, openManageExtensionPopup})}
                />
                <DeletedConfirmationPopup
                    title={shouldShowChangeCoachButton(openConfirmationPopup) ? 'Le coaché souhaite t\'il poursuivre avec vous le parcours ?' : 'Voulez-vous vraiment clôturer le coaching ?'}
                    disable={disable}
                    handleAccept={shouldShowChangeCoachButton(openConfirmationPopup) ? () => handleChangeCoach({ setCoachings, setTransition, setText, setSeverity, setOpenSnackbar, setDisable, openConfirmationPopup, setOpenConfirmationPopup, setDashboardData}) : () => handleCloseCoaching({setCoachings, history, openConfirmationPopup, setOpenConfirmationPopup, setTransition, setText, setSeverity, setOpenSnackbar, setDisable, setDashboardData})}
                    open={openConfirmationPopup} 
                    handleClose={() => handleCloseConfirmationPopup({setOpenConfirmationPopup})}
                />
            </Box>
        </Box>
    );
}