import { Paper, Typography, Zoom, Grid, Button } from '@mui/material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import RessourceBand from '../img/Bandeau-ressource-vue-RH.jpg'
import { makeStyles } from '@material-ui/core';
import AudioPlayer from 'material-ui-audio-player';
import audio from '../asset/podcast-temoignage.mp3'

export default function Ressources({ zoom }) {
    const [toggle, setToggle] = useState(false)
    const useStyles = makeStyles((theme) => {
        return {
            root: {
                backgroundColor: '#01304A',
                marginTop: 50,
                marginLeft: 30,
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
            },
            loopIcon: {
                color: '#ECA914',
                '&.selected': {
                    color: '#ECA914',
                },
                '&:hover': {
                    color: '#ECA914',
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            playIcon: {
                color: '#ECA914',
                '&:hover': {
                    color: '#ECA914',
                },
            },
            replayIcon: {
                color: '#ECA914',
            },
            pauseIcon: {
                color: '#ECA914',
            },
            volumeIcon: {
                color: '#ECA914',
                '&:hover': {
                    color: '#ECA914',
                },
            },
            volumeSlider: {
                color: '#ECA914',
            },
            progressTime: {
                color: '#ECA914',
            },
            mainSlider: {
                color: '#ECA914',
                marginTop: -10,
                '& .MuiSlider-rail': {
                    color: '#ECA914',
                    height: 20,
                    borderRadius: 40,
                },
                '& .MuiSlider-track': {
                    color: '#ECA914',
                    height: 20,
                    borderRadius: 40,
                },
                '& .MuiSlider-thumb': {
                    color: '#ECA914',
                    display: 'none',
                },
            },
        };  
    });
    let history = useHistory()
    document.title = 'Wellmonday - Ressources'
    const ressources = [
        {title: 'Guide RH', content: 'Le guide du coaching à distance', button: 'Télécharger'},
        {title: 'Livre blanc', content: 'Les 7 choses à savoir avant de démarrer un coaching', button: 'Télécharger'},
        {title: 'Podcast', content: 'Témoignage - Alice Thomas, Directrice Générale déléguée de Pros-Consulte', button: 'écouter'},
        {title: 'Webinaire', content: 'Visionnez le replay de notre webinaire - Le coaching, levier de performance et de bien-être au travail', button: 'Replay'},
    ]

    const handleFindResource = (e) => {
        if (e.currentTarget.title === '_blank') {
            window.open('https://webikeo.fr/webinar/le-coaching-levier-de-performance-et-de-bien-etre-au-travail-11', "_blank")
        } else if (e.currentTarget.title) {
            history.push('/' + e.currentTarget.title)

        } else {
            setToggle(true)
        }
    }

    return (
        <Zoom in={zoom} timeout={700}>
            <div>
                <img src={RessourceBand} alt='coaching 100% digital' width='100%' />
                <Typography variant='h4' color='secondary' style={{fontWeight: 'bold', marginTop: 20}}>Bonnes pratiques en coaching</Typography>
                {toggle && <AudioPlayer
                    width="95%"
                    variation="primary"
                    download={false}
                    useStyles={useStyles}
                    autoplay={true}
                    order="standart"
                    preload="auto"
                    loop={true}
                    src={audio}
                    onPaused={() => setToggle(false)}
                />}
                {ressources.map((ressource, index) => {
                    return (
                        <Zoom timeout={700} in={zoom} key={index}  style={{ transitionDelay: index * 100 + 200 + 'ms' }}>
                            <Paper elevation={16} sx={{mt: 3, padding: 4,  borderBottom: '3px solid #ECA914'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography style={{marginTop: 5}} variant='h6' color='primary'>{ressource.title}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography style={{marginTop: 5}} variant='body1'>{ressource.content}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} style={{textAlign: 'center'}}>
                                        <Button
                                            style={{width: 130}}
                                            variant='contained'
                                            color='primary'
                                            title={
                                                ressource.title === 'Guide RH' ?
                                                'telechargement-du-livre/guide' :
                                                ressource.title === 'Livre blanc' ?
                                                'telechargement-du-livre/coaching' :
                                                ressource.title === 'Webinaire' ?
                                                '_blank' :
                                                ''
                                            }
                                            onClick={handleFindResource}
                                        >
                                                {ressource.button}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Zoom>
                    )
                })}
            </div>
        </Zoom>
    )
}