import { VisibilityOff, Visibility } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Divider, IconButton, InputAdornment, Paper, TextField, Zoom, ButtonGroup, Typography, CircularProgress} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import AlertSnackbar from '../components/AlertSnackbar'
import ProgressBar from '../components/ProgressBar'
import { progressBarWidth, TransitionLeft } from '../helper/utils'
import { apiFetch } from '../service/security/apiFetch'
import logo from '../img/logo.png'
import { handleFormError } from '../service/security/error'

export default function ValidateUser() {
    const { id } = useParams()
    const [token, setToken] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [transition, setTransition] = useState(false)
    const [text, setText] = useState(false)
    const [severity, setSeverity] = useState(false)
    const [style, setStyle] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({})
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    let history = useHistory()

    useEffect(() => {
        (async () => {
            const getToken = await apiFetch(`/validation/user/${id}`, {method: 'GET'}, false, false, false, {'Content-Type': 'application/json',})
            if (getToken && getToken.uuid) {
                setToken(getToken)
            } else {
                setTransition(() => TransitionLeft)
                setText('Le token d\'inscription n\'est plus valide !')
                setSeverity('error')
                setOpenSnackbar(true)
                setTimeout(() => {
                    history.push('/')
                    window.location.reload()
                }, 3000)
            }
        })()
    }, [id, history])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError({})

        const data = new FormData(e.target)
        const password = data.get('password')
        const confirmNewPassword = data.get('confirmNewPassword')

        if (!confirmNewPassword || !password) {
            if (!password) setError({...error, password: 'Veuillez renseigner ce champ'})
            if (!confirmNewPassword) setError({...error, confirmNewPassword: 'Veuillez renseigner ce champ'})
            setLoading(false)
            return
        }

        if (password !== confirmNewPassword) {
            setLoading(false)
            return setError({confirmNewPassword: 'Ce mot de passe n\'est pas identique au nouveau mot de passe'})
        }

        const dataObject = {
            password: password,
        }  

        const addPassword = await apiFetch(`/users/${token.uuid}/initialization/${id}`, {
            method: 'PUT',
            body: JSON.stringify(dataObject)
        }, false, false, false, {'Content-Type': 'application/json',})

        if (addPassword && addPassword.type) {
            setText('Mot de passe enregistré avec succès, vous allez être redirigé vers l\'interface de connexion dans 5 secondes !')
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
            setTimeout(() => {
                history.push('/login')
                window.location.reload()
            }, 5000)
        } else {
            const formError = handleFormError(addPassword, ['password'])
            setError(formError)
            setText('Erreur lors de la création du mot de passe, veuillez réessayer ultérieurement')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setLoading(false)
    }    

    const handleClickPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleClickConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const handleChangeProgressBar = (e) => {
        progressBarWidth(e, setStyle)
    }

    return (
        <>
            <Zoom in={!!token} timeout={700}>
                <div>
                    <img src={logo} alt="logo" width='300px'/>
                    <Paper elevation={16} sx={{ padding: '40px 80px', textAlign: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <form noValidate onSubmit={handleSubmit} autoComplete="off">
                                <Typography variant='h5' color={'primary'} style={{textAlign: 'center'}}>Choisir un mot de passe</Typography>
                                <Typography variant='body1' color={'secondary'} style={{textAlign: 'center'}}>Pour pouvoir accéder à votre espace personnel</Typography>
                                <Typography variant='body1' color={'secondary'} style={{textAlign: 'center'}}>Veuillez créer votre mot de passe</Typography>
                                <Divider style={{marginTop: 20}} />
                                <TextField 
                                    id="password" 
                                    name="password" 
                                    label="Mot de passe"
                                    type={showPassword ? "text" : "password"}
                                    sx={{marginTop: 5, width: {xl: 400, lg: 400, md: 400, sm: 200, xs: 300}}}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    required
                                    autoComplete="on"
                                    onChange={handleChangeProgressBar}
                                    error={!!error.password} helperText={error.password}
                                /><br />
                                <ProgressBar style={style} />
                                <TextField 
                                    id="confirmNewPassword" 
                                    name="confirmNewPassword" 
                                    label="Confirmer le mot de passe"
                                    type={showConfirmPassword ? "text" : "password"}
                                    sx={{marginTop: 5, width: {xl: 400, lg: 400, md: 400, sm: 200, xs: 300}}}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickConfirmPassword}
                                                >
                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    required
                                    autoComplete="on"
                                    error={!!error.confirmNewPassword}
                                    helperText={error.confirmNewPassword}
                                /><br />
                                <ButtonGroup style={{marginTop: 40}} color={'primary'} variant="contained" aria-label="contained primary button group">
                                    <LoadingButton
                                        type='submit'
                                        loading={loading}
                                        color={'primary'}
                                        variant="contained"
                                    >
                                        Enregistrer
                                    </LoadingButton>
                                </ButtonGroup>
                        </form>
                    </Paper>
                </div>
            </Zoom>
            <AlertSnackbar open={openSnackbar} setOpen={setOpenSnackbar} transition={transition} message={text} severity={severity} />
            {!token && <div style={{textAlign: 'center'}}>
                <CircularProgress />    
            </div>}
        </>
    )
}