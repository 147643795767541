import React, { useEffect, useState } from 'react'
import {
    Typography,
    Paper,
    Button,
    Zoom,
    ButtonGroup,
    Grid,
    Avatar,
} from '@mui/material';
import SkillsPopup from '../../components/SkillsPopup';
import { apiFetch } from '../../service/security/apiFetch';
import CoachPopup from '../../components/CoachPopup';

export default function ChooseCoach({ zoom, handleNext, setCoach, coachProposal, coach, disable }) {
    const [openPopup, setOpenPopup] = useState(false)
    const [coachs, setCoachs] = useState(false)

    const handleClickCoach = (info) => {
        setOpenPopup(info)
    }

    const handleClose = () => {
        setOpenPopup(false)
    }

    const handleChoose = () => {
        setCoach(openPopup)
        setOpenPopup(false)
    }

    useEffect(() => {
        (async () => {
            const fetchCoachs = await apiFetch(`${coachProposal.proposal['@id']}`)
            if (fetchCoachs && fetchCoachs.coachs  && fetchCoachs.coachs.length) {
                setCoachs(fetchCoachs)
            }
        })()
    }, [coachProposal])

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '500ms' }}>
            <div>
                <Paper elevation={16} sx={{maxWidth: 1100, margin: 'auto', textAlign: 'center', p: '0px 100px 20px 100px', }}>
                    <Typography variant='h6' sx={{textAlign: 'center', mt: 2, pt: 2}}>
                        Choisissez votre coach :
                    </Typography>
                    <Typography variant='body1' sx={{textAlign: 'center', mt: 2, pt: 2}}>
                        Prenez le temps de découvrir ces coachs, afin de choisir celui qui vous conviendra le mieux
                    </Typography>
                    <ButtonGroup variant="contained" sx={{height: 50, mt: 3}} >
                        <Button onClick={handleNext} sx={{width: 150}} disabled={disable}>Terminer</Button>
                    </ButtonGroup>
                    <SkillsPopup open={openPopup} handleClose={handleClose} handleChoose={handleChoose} />
                </Paper>
                <Grid container sx={{mt: 4, maxWidth: 1200, margin: '50px auto'}}>
                    {coachs && coachs.coachs.map((el, index) => {
                        return (
                            <Zoom in={true} timeout={700} style={{ transitionDelay: '800ms' }} key={index}>
                                <Grid item xs={12} md={4}>
                                    <Paper elevation={16} sx={{ p: 5, maxWidth: 300, margin: 'auto', textAlign: 'center', backgroundColor: coach.description === el.description ? '#ECA914' : ''}}>
                                        <Avatar src={el.photo.contentUrl} alt={"Coach"} sx={{margin: 'auto', width: 120, height: 120}} />
                                        <Typography variant='h6' sx={{mt: 2, fontSize: 26, fontWeight: 'bold'}} color='secondary'>
                                            {el.user.firstName} {el.user.lastName[0]}
                                        </Typography>
                                        <Typography variant='body1' sx={{mt: 2}}>
                                            {el.shortDescription}
                                        </Typography>
                                        <Button sx={{mt: 1}} onClick={() => handleClickCoach(el)}>découvrir</Button>
                                    </Paper>
                                </Grid>
                            </Zoom>
                        )
                    })}
                </Grid>
                <CoachPopup open={openPopup} handleClose={handleClose} handleChoose={handleChoose} />
            </div>
        </Zoom>
    )
}