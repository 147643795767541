import React from 'react'
import {
    Zoom,
    Paper,
    Typography,
    Grid,
    Button,
    Tooltip,
} from '@mui/material/';
import { useHistory } from 'react-router-dom';
import moment from 'moment'

export default function ScheduledSession({ zoom, dashboardData }) {
    let history = useHistory()

    const handleVisio = (row) => {
        window.open(row.visioUrl, "_blank")
    }

    return (
        <Zoom in={zoom} timeout={700}>
            <Paper elevation={16} sx={{padding: 3, backgroundColor: '#01304A', }}>
                <Grid container>
                    <Grid item xs={12} sm={9}>
                        <Typography variant='h3' sx={{fontSize: 28, fontWeight: 'bold', mb: 2, color: '#FFF'}}>
                            Prochaine séance
                        </Typography>
                        <Typography variant='body1' sx={{fontSize: 18,mt: 1, color: '#FFF'}}>
                            <span style={{marginRight: 15, fontStyle: 'italic'}}>Date :</span> {dashboardData.currentCoaching && dashboardData.currentCoaching.nextSeance ? moment(dashboardData.currentCoaching.nextSeance.availability.startAt).format('DD MMMM YYYY') : 'à planifier'}
                        </Typography>
                        <Typography variant='body1' sx={{fontSize: 18,mt: 1, color: '#FFF'}}>
                            <span style={{marginRight: 15, fontStyle: 'italic'}}>Heure :</span> {dashboardData.currentCoaching && dashboardData.currentCoaching.nextSeance ? moment(dashboardData.currentCoaching.nextSeance.availability.startAt).format('HH:mm') : 'à planifier'}
                        </Typography>
                        <Typography variant='body1' sx={{fontSize: 18,mt: 1, color: '#FFF'}}>
                            <span style={{marginRight: 15, fontStyle: 'italic'}}>Coach :</span> {dashboardData.currentCoaching && dashboardData.currentCoaching.coach ? `${dashboardData.currentCoaching.coach.user.firstName} ${dashboardData.currentCoaching.coach.user.lastName}` : 'à déterminer'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {dashboardData.currentCoaching && dashboardData.currentCoaching.coach && !dashboardData.currentCoaching.nextSeance ? <Button variant='contained' onClick={() => history.push('/prendre-un-rdv')}>Planifier</Button>: 
                        dashboardData.currentCoaching && dashboardData.currentCoaching.coach && dashboardData.currentCoaching.nextSeance && <Tooltip title={!dashboardData.currentCoaching.nextSeance.visioUrl && "Vous pouvez rejoindre la visio à partir de 5 min avant le début de la séance"} arrow><span><Button variant='contained' onClick={dashboardData.currentCoaching.nextSeance.visioUrl && (() => handleVisio(dashboardData.currentCoaching.nextSeance))}>Rejoindre la Visio</Button></span></Tooltip>}
                    </Grid>
                </Grid>
            </Paper>
        </Zoom>
    )
}