import {
    Typography,
    Zoom,
    CircularProgress,
    Avatar,
    Toolbar,
    Box,
    Table,
    TablePagination,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Paper,
    TableContainer,
    TableBody,
    MenuItem,
    Menu,
    IconButton,
} from '@mui/material'
import React, { useEffect, useState, } from 'react'
import { useHistory } from 'react-router-dom'
import { TransitionLeft } from '../helper/utils'
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { ThemeProvider } from '@mui/styles'
import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles'
import DeletedConfirmationPopup from '../components/DeletedConfirmationPopup'
import { useCoaches } from '../hook/coachs'
import AddCoach from './CoachesItem/AddCoach';
import { handleFormError } from '../service/security/error';
import { apiFetch } from '../service/security/apiFetch';
import { MoreVert, Edit, ExitToApp, ToggleOff, ToggleOn } from '@mui/icons-material';
import { isAuth } from '../service/security/isAuth';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {id: 'logo', numeric: false, disablePadding: false, label: 'Logo'},
    {id: 'email', numeric: true, disablePadding: false, label: 'Email', },
    {id: 'firstName', numeric: true, disablePadding: false, label: 'Prénom', },
    {id: 'lastName', numeric: true, disablePadding: false, label: 'Nom', },
    {id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile', },
    {id: 'description', numeric: true, disablePadding: false, label: 'Description', },
    {id: 'active', numeric: true, disablePadding: false, label: 'Actif', },
    {id: 'action', numeric: true, disablePadding: false, label: 'Actions', },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{color: '#01304A', fontWeight: 'bold'}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%', color: '#ECA914' }}
                variant="h4"
                id="tableTitle"
                component="div"
            >
                Coachs
            </Typography>
        </Toolbar>
    );
};

export default function Coachs({ zoom, setSeverity, setText, setTransition, setOpenSnackbar, setOpenBackdrop, setUser }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [page, setPage] = useState(0);
    const [openPopup, setOpenPopup] = useState(false)
    const [photo, setPhoto] = useState(false)
    const [disable, setDisable] = useState(false)
    const [selected, setSelected] = useState(false)
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [error, setError] = useState({})
    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        description: '',
    })
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [locale] = useState('frFR');
    let history = useHistory()
    document.title = 'Wellmonday - Liste des coachs'

    const  {
        coaches,
        fetchCoaches,
        deleteCoaches,
        updateCoaches,
    } = useCoaches()

    useEffect(() => {
        (async () => {
            const fetch = await fetchCoaches()
            if (fetch && fetch['hydra:description']) {
                setSeverity('error')
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                history.goBack()
            }
        })()
    }, [fetchCoaches, setSeverity, setText, setTransition, setOpenSnackbar, history])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Avoid a layout jump when reaching the last page with empty coaches.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * 30 - coaches.length) : 0;

    const manageCoach = async () => {
        setDisable(true)
        setError({})
        const firstName = state.firstName
        const lastName = state.lastName
        const email = state.email
        const mobile = state.mobile
        const description = state.description

        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData() 
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/photo_objects', {
                method: 'POST',
                body: dataPhoto,
            }, true)
        }
        let body = {user: {firstName: firstName, lastName: lastName, email: email, mobile: mobile }, description: description}
        if (photo) {
            body.photo = createPhoto && createPhoto['@id']
        }
        if (selected) {
            body.user['@id'] = selected.user['@id']
        }

        const manage = await updateCoaches(selected.uuid, body, '/coaches')
        if (manage && manage.uuid) {
            setText('Le coach a été modifié')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenPopup(false)
            setState({
                firstName: '',
                lastName: '',
                email: '',
                mobile: '',
                description: '',
            })
            handleCloseMenu()
        } else {
            const formError = handleFormError(manage, ['user.lastName', 'user.firstName', 'user.email', 'user.mobile', 'description'])
            setError(formError)
            setSeverity('error')
            setText('Erreur lors de l\'ajout du coach !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDisable(false)
    }

    const handleConfirmationDelete = () => {
        setOpenDeletePopup(!openDeletePopup)

    }

    const handleDelete = async () => {
        setOpenDeletePopup(!openDeletePopup)
        setOpenBackdrop(true)
        const deletedCoach = await deleteCoaches(selected.uuid)

        if (deletedCoach && deletedCoach.ok) {
            setText('Le coach a été supprimé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            handleCloseMenu()
        } else {
            setText((deletedCoach && deletedCoach['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setOpenBackdrop(false)
        setSelected(false)
    }

    const handleModifyPopup = () => {
        setState({
            firstName: selected.user.firstName,
            lastName: selected.user.lastName,
            email: selected.user.email,
            mobile: selected.user.mobile,
            description: selected.description,
        })
        setOpenPopup(true)
    }

    const handleClose = () => {
        setState({
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            description: '',
        })
        setOpenPopup(false)
        setSelected(false)
    }

    const handleToggleActive = async () => {
        const changeActive = await updateCoaches(selected.uuid, {active: !selected.active}, '/coaches')
        setDisable(true)
        if (changeActive && changeActive.uuid) {
            setText('Le coach a été modifié')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            handleCloseMenu()
        } else {
            setText((changeActive && changeActive['hydra:description']) || 'Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setDisable(false)
    }

    const onLoginAs = async () => {
        const loginAs = await apiFetch(`/impersonate?_switch_user=${encodeURIComponent(selected.user.email)}`)
        if (loginAs && loginAs.token) {
            localStorage.setItem('REACT_ROLE', JSON.stringify('Coach'))
            localStorage.setItem('REACT_ROLE_ORIGIN', JSON.stringify('Admin'))
            localStorage.setItem('REACT_TOKEN_AUTH_ORIGIN', JSON.stringify(isAuth()))
            localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(loginAs))
            history.push('/dashboard')
            const fetchUser = await apiFetch('/users/me')
            if (fetchUser && fetchUser['@id']) {
                setUser(fetchUser)
            }
        }
    }

    const handleClickMenu = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelected(row)
    };

    const handleCloseMenu = () => {
        setAnchorEl(false)
        setSelected(false)
    }

    return (
        <Zoom in={zoom} timeout={700}>
            <Box sx={{ width: '100%' }}>
                <Paper elevation={16} sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar />
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                   coaches.slice().sort(getComparator(order, orderBy)) */}
                                {coaches ? stableSort(coaches, getComparator(order, orderBy))
                                    .slice(page * 30, page * 30 + 30)
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.uuid}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="normal"
                                                >
                                                    <Avatar src={row.photo && row.photo.contentUrl} sx={{width: 70, height: 70}} />
                                                </TableCell>
                                                <TableCell align="right">{row.user.email}</TableCell>
                                                <TableCell align="right">{row.user.firstName}</TableCell>
                                                <TableCell align="right">{row.user.lastName}</TableCell>
                                                <TableCell align="right">{row.user.mobile}</TableCell>
                                                <TableCell align="right">{row.description}</TableCell>
                                                <TableCell align="right">{row.active ? 'Oui' : 'Non'}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton onClick={(e) => handleClickMenu(e, row)} style={{marginRight: 5}} ><MoreVert /></IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                }) : <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={3} />
                                    <TableCell>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                        {coaches && <TablePagination
                            labelRowsPerPage=''
                            rowsPerPageOptions={[]}
                            component="div"
                            count={coaches.length}
                            rowsPerPage={30}
                            page={page}
                            onPageChange={handleChangePage}
                        />}
                    </ThemeProvider>
                </Paper>
                <AddCoach state={state} setState={setState} disable={disable} selected={selected} error={error} open={openPopup} handleClose={handleClose} photo={photo} setPhoto={setPhoto} handleAdd={manageCoach} />
                <DeletedConfirmationPopup handleAccept={handleDelete} open={openDeletePopup} handleClose={handleConfirmationDelete} />
                <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                        'aria-labelledby': 'button',
                    }}
                >
                    <MenuItem onClick={() => handleModifyPopup()}><Edit style={{color: 'gray', marginRight: 8}} />Modifier</MenuItem>
                    <MenuItem onClick={() => handleToggleActive()}>{selected.active ? <ToggleOff style={{color: 'gray', marginRight: 8}} /> : <ToggleOn style={{color: 'gray', marginRight: 8}} />}{selected.active ? 'Désactiver' : 'Activer'}</MenuItem>
                    <MenuItem onClick={() => onLoginAs()}><ExitToApp style={{color: 'gray', marginRight: 8}} />Se connecter</MenuItem>
                </Menu>
            </Box>
        </Zoom>
    );  
}
