import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import {
    Stack,
    Stepper,
    Step,
    StepLabel,
    LinearProgress
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import ChooseSoftSkills from './InitializingCoachingItem/ChooseSoftSkills';
import SelfDiagnosis from './InitializingCoachingItem/SelfDiagnosis';
import ChooseCoach from './InitializingCoachingItem/ChooseCoach';
import Motivation from './InitializingCoachingItem/Motivation';
import { download, TransitionLeft } from '../helper/utils';
import { useHistory, useParams } from 'react-router-dom';
import { apiFetch } from '../service/security/apiFetch';
import { useRef } from 'react';
import { handleFormError } from '../service/security/error';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#ECA914',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#ECA914',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#ECA914',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#ECA914',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

const steps = ['Choix de soft skill', 'Vos motivations', 'Autodiagnostic soft skills', 'Choix du coach'];

export default function InitializingCoaching({zoom, setOpenSnackbar, setText, setTransition, setSeverity, user}) {
    const [activeStep, setActiveStep] = useState(4)
    const [selectedSkills, setSelectedSkills] = useState(false)
    const [startSelfDiagnosis, setStartSelfDiagnosis] = useState(false)
    const [quizSumUp, setQuizSumUp] = useState(false)
    const [questions, setQuestions] = useState([])
    const [coaching, setCoaching] = useState(false)
    const [coachProposal, setCoachProposal] = useState(false)
    const [isDownloaded, setIsDownloaded] = useState(false)
    const [disable, setDisable] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [coach, setCoach] = useState(false)
    const [textArea, setTextArea] = useState('')
    const [error, setError] = useState({})
    const [age, setAge] = useState('')
    const [gender, setGender] = useState('')
    document.title = 'Wellmonday - Démarche de choix du coach'

    const { id } = useParams()
    const formRef = useRef()
    let history = useHistory()

    const findInitStep = (params) => {
        switch(params) {
            case 'softskill':
                return 0
            case 'expectation':
                return 1
            case 'quiz':
                return 2
            case 'coach':
                return 3
            default:
                return 0
        }
    }

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <ChooseSoftSkills disable={disable} handleBack={handleBack} handleNext={handleNext} selectedSkills={selectedSkills} setSelectedSkills={setSelectedSkills} zoom={zoom} />
            case 1:
                return <Motivation disable={disable} zoom={zoom} handleBack={handleBack} handleNext={handleNext} textArea={textArea} setTextArea={setTextArea} />
            case 2:
                return <SelfDiagnosis isDownloaded={isDownloaded} disable={disable} handleDownload={handleDownload} quizSumUp={quizSumUp} age={age} setAge={setAge} gender={gender} setGender={setGender} error={error} formRef={formRef} handleSubmitQuestion={handleSubmitQuestion} questions={questions} coaching={coaching} startSelfDiagnosis={startSelfDiagnosis} zoom={zoom} handleBack={handleBack} handleNext={handleNext} />
            case 3:
                return <ChooseCoach disable={disable} coachProposal={coachProposal || coaching} zoom={zoom} handleBack={handleBack} coach={coach} setCoach={setCoach} handleNext={handleNext} />
            default:
                return '';
        }
    }

    const handleDownload = async () => {
        setDisable(true)
        const fetchBlob = await apiFetch(`/hexaco_student_results/${quizSumUp.uuid}/download`, {}, false, false, true)

        if (fetchBlob.ok) {
          const blob = await fetchBlob.blob()
          download(blob, `résultat-quiz-hexaco`)
        }
        setIsDownloaded(true)
        setDisable(false)
    }

    const putChoosenSkills = async () => {
        const choosenSkill = await apiFetch(`/coachings/${id}`, {
            method: 'PUT',
            body: JSON.stringify({softSkill: selectedSkills['@id']})
        })
        return choosenSkill
    } 

    const putExpectation = async () => {
        const expectation = await apiFetch(`/coachings/${id}`, {
            method: 'PUT',
            body: JSON.stringify({expectation: textArea})
        })
        return expectation
    }

    const putCoach = async () => {
        const selectedCoach = await apiFetch(`/coachings/${id}`, {
            method: 'PUT',
            body: JSON.stringify({coach: coach['@id']})
        })
        setCoachProposal(selectedCoach)
        return selectedCoach
    }

    const putQuestion = async (answeredQuestionsTab) => {
        const selectedCoach = await apiFetch(`/hexaco_quizzes/multiples`, {
            method: 'POST',
            body: JSON.stringify({
                quiz: `/hexaco_quizzes/${coaching.hexacoQuiz.uuid}`,
                answers: answeredQuestionsTab
            })
        })
        return selectedCoach
    }

    const handleNext = async (quizParam) => {
        setDisable(true)
        if (activeStep === 0) {
            if (!selectedSkills) {
                setText('Vous devez sélectionner une soft skills pour continuer')
                setTransition(() => TransitionLeft)
                setSeverity('warning')
                setOpenSnackbar(true)
            } else {
                const fetch = await putChoosenSkills()
                if (fetch && !fetch['hydra:description']) {
                    setActiveStep(1)
                } else {
                    setText((fetch && fetch['hydra:description']) || 'Erreur lors du choix de la soft skills !')
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setOpenSnackbar(true)
                }
            }
        }
        if (activeStep === 1) {
            if (!textArea) {
                setText('Vous devez écrire minimum 3 caractères')
                setTransition(() => TransitionLeft)
                setSeverity('warning')
                setOpenSnackbar(true)
            } else {
                const fetch = await putExpectation()
                if (fetch) {
                    setCoaching(fetch)
                    setActiveStep(2)
                } else {
                    setText((fetch && fetch['hydra:description']) || 'Erreur lors de la saisie du texte !')
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setOpenSnackbar(true)
                }
            }
        }
        if (activeStep === 2) {
            if (coaching.hexacoQuiz.status === 'created' && !startSelfDiagnosis) {
                const putAgeGender = await apiFetch(`/hexaco_quizzes/${coaching.hexacoQuiz.uuid}`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        age: parseInt(age),
                        sexe: gender,
                    })
                })
                if (putAgeGender && putAgeGender.uuid) {
                    const fetchQuestions = await apiFetch(`/hexaco_quizzes?coaching.uuid=${coaching.uuid}`)
                    if (fetchQuestions && fetchQuestions['hydra:member']) {
                        setStartSelfDiagnosis(true)
                        setQuestions(fetchQuestions['hydra:member'][0])
                    } else {
                        setText((fetch && fetch['hydra:description']) || 'Erreur lors de la récupération des questions !')
                        setTransition(() => TransitionLeft)
                        setSeverity('error')
                        setOpenSnackbar(true)
                    }
                } else {
                    const formError = handleFormError(putAgeGender, ['sexe', 'age'])
                    setError(formError)
                    setText('Vous devez renseignez votre tous les champs avant de continuer !')
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setOpenSnackbar(true)
                }
            } else {
                if (quizParam && !quizParam.nextQuestions.length) {
                    if (!quizSumUp) {
                        const fetchQuizHexaco = await apiFetch(`/hexaco_quizzes/${coaching.hexacoQuiz.uuid}`)
                        if (fetchQuizHexaco.result) {
                            setQuizSumUp(fetchQuizHexaco.result)
                        }
                    } else {
                        const fetchCoaching = await apiFetch(`/coachings/${id}`)
                        if (fetchCoaching) {
                            setCoaching(fetchCoaching)
                            setDisable(false)
                            return setActiveStep(3)
                        }
                    }
                }
            }
        }
        if (activeStep === 3) {
            if (!coach) {
                setText('Vous devez sélectionner un coach pour terminer')
                setTransition(() => TransitionLeft)
                setSeverity('warning')
                setOpenSnackbar(true)
            } else {
                const fetch = await putCoach()
                if (fetch) {
                    history.push('/prendre-un-rdv')
                } else {
                    setText((fetch && fetch['hydra:description']) || 'Erreur lors du choix du coach !')
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setOpenSnackbar(true)
                }
            }
        }
        setDisable(false)
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }

    useEffect(() => {
        (async () => {
            const fetchCoaching = await apiFetch(`/coachings/${id}`)
            if (fetchCoaching) {
                setCoaching(fetchCoaching)
                setActiveStep(findInitStep(fetchCoaching && fetchCoaching.initStep))
                const fetchQuestions = await apiFetch(`/hexaco_quizzes?coaching.uuid=${fetchCoaching.uuid}`)
                if (fetchQuestions && fetchQuestions['hydra:member'] && fetchQuestions['hydra:member'].length) {
                    setQuestions(fetchQuestions['hydra:member'][0])
                }
            }
        })()
    }, [id])

    const handleSubmitQuestion = async (e) => {
        e.preventDefault()
        let data = new FormData(formRef.current)
        const answeredQuestionsTab = []
        for(var pair of data) {
            answeredQuestionsTab.push({uuid: pair[0], note: parseInt(pair[1])})
        }

        if (answeredQuestionsTab.length === 10) {
            setRefresh(true)
            const fetch = await putQuestion(answeredQuestionsTab)
            if (fetch && !fetch.violations) {
                if (fetch.status === 'done') {
                    handleNext(fetch)
                } else {
                    setQuestions(fetch)
                }
            } else {
                setText((fetch && fetch['hydra:description']) || 'Erreur lors de la validation des questions !')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
            setRefresh(false)
        } else {
            setText('Vous devez répondre à toutes les questions avant de continuer !')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {!refresh ? getStepContent(activeStep) : <LinearProgress />}
            </div>
        </Stack>
    )
}