import React from 'react'
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material/';
import MailIcon from '@mui/icons-material/Mail';
import { useHistory } from 'react-router-dom'
import { hasRoles } from '../../service/security/hasRoles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SportsIcon from '@mui/icons-material/Sports';
import HandymanIcon from '@mui/icons-material/Handyman';
import LogoutIcon from '@mui/icons-material/Logout';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import BusinessIcon from '@mui/icons-material/Business'
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';
import { isLoginAsUser } from '../../service/security/isAuth';
import PeopleIcon from '@mui/icons-material/People';

let temporaryRoute = null
export default function Navigation({ setZoom, handleDrawerToggle, handleDisconnect, user, handleRestoreUser }) {
    let history = useHistory()

    const studentTab = [
        {path: 'dashboard', item: 'Tableau de bord', icon: <DashboardIcon style={{color: '#FFF'}} />},
        {path: 'profil', item: 'Mon profil', icon: <AccountCircleIcon style={{color: '#FFF'}} />},
        {path: 'coaching',item: 'Mes coachings', icon: <SportsIcon style={{color: '#FFF'}} />},
        {path: 'messages',item: 'Messagerie', icon: <MailIcon style={{color: '#FFF'}} />},
        {path: 'toolbox',item: 'Boîte à outils', icon: <HandymanIcon style={{color: '#FFF'}} />},
        {path: 'disconnect',item: 'Se déconnecter', icon: <LogoutIcon style={{color: '#FFF'}} />},
    ]

    const coachTab = [
        {path: 'dashboard', item: 'Tableau de bord', icon: <DashboardIcon style={{color: '#FFF'}} />},
        {path: 'profil', item: 'Mon profil', icon: <AccountCircleIcon style={{color: '#FFF'}} />},
        {path: 'coaching',item: 'Mes coachings', icon: <SportsIcon style={{color: '#FFF'}} />},
        {path: 'messages',item: 'Messagerie', icon: <MailIcon style={{color: '#FFF'}} />},
        {path: 'planning',item: 'Mon planning', icon: <CalendarTodayIcon style={{color: '#FFF'}} />},
        {path: 'toolbox',item: 'Boîte à outils', icon: <HandymanIcon style={{color: '#FFF'}} />},
        {path: 'disconnect',item: 'Se déconnecter', icon: <LogoutIcon style={{color: '#FFF'}} />},
    ]

    const adminTab = [
        {path: 'dashboard', item: 'Tableau de bord', icon: <DashboardIcon style={{color: '#FFF'}} />},
        {path: 'sessions',item: 'Sessions de coachings', icon: <SportsIcon style={{color: '#FFF'}} />},
        {path: 'entreprises',item: 'Entreprises', icon: <BusinessIcon style={{color: '#FFF'}} />},
        {path: 'messages',item: 'Messagerie', icon: <MailIcon style={{color: '#FFF'}} />},
        {path: 'toolbox',item: 'Boîte à outils', icon: <HandymanIcon style={{color: '#FFF'}} />},
        {path: 'planning',item: 'Planning', icon: <CalendarTodayIcon style={{color: '#FFF'}} />},
        {path: 'coachs',item: 'Liste des coachs', icon: <CreditScoreIcon style={{color: '#FFF'}} />},
        {path: 'gestion-du-blog',item: 'Gérer le blog', icon: <ArticleIcon style={{color: '#FFF'}} />},
        {path: 'gestion-des-utilisateurs',item: 'Gérer les utilisateurs', icon: <PeopleIcon style={{color: '#FFF'}} />},
        {path: 'parametres',item: 'Paramètres', icon: <SettingsIcon style={{color: '#FFF'}} />},
        {path: 'disconnect',item: 'Se déconnecter', icon: <LogoutIcon style={{color: '#FFF'}} />},
    ]

    const userTab = [
        {path: 'dashboard', item: 'Tableau de bord', icon: <DashboardIcon style={{color: '#FFF'}} />},
        {path: 'disconnect',item: 'Se déconnecter', icon: <LogoutIcon style={{color: '#FFF'}} />},
    ]

    const managerTab = [
        {path: 'dashboard',item: 'Tableau de bord', icon: <DashboardIcon style={{color: '#FFF'}} />},
        {path: 'profil', item: 'Mon profil', icon: <AccountCircleIcon style={{color: '#FFF'}} />},
        {path: 'ressources',item: 'Bonnes pratiques', icon: <ArticleIcon style={{color: '#FFF'}} />},
        {path: 'disconnect',item: 'Se déconnecter', icon: <LogoutIcon style={{color: '#FFF'}} />},
    ]
    
    const handleNavigation = (e) => {
        setZoom(false)
        if (e.currentTarget.title === 'disconnect') {
            return handleDisconnect()
        }
        temporaryRoute = e.currentTarget.title
        if (handleDrawerToggle) {
            handleDrawerToggle(false)
        }
        setTimeout(() => {
            setZoom(true)
            history.push(`/${temporaryRoute}`)
            temporaryRoute = null
        }, 50)
    }

    return (
        <div style={{background: user && user.student ? '#ECA914 0% 0% no-repeat padding-box' : user && user.manager ? '#7f7f7f 0% 0% no-repeat padding-box' :  '#01304A 0% 0% no-repeat padding-box', color: '#FFF'}}>
            <List>
                {findRightMenu(studentTab, adminTab, coachTab, userTab, managerTab).map((item) => (
                    <ListItem id='tab' key={item.path} title={item.path} button onClick={handleNavigation}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.item} />
                    </ListItem>
                ))}
                {isLoginAsUser() && <ListItem id='tab' button onClick={handleRestoreUser}>
                    <ListItemIcon><LogoutIcon style={{color: '#FFF'}} /></ListItemIcon>
                    <ListItemText primary={'Quitter la session'} />
                </ListItem>}
            </List>
        </div>
    )
}

function findRightMenu(studentTab = [], adminTab = [], coachTab = [], userTab = [], managerTab = [], prosConsulteTab = []) {
    if (hasRoles() === 'student') {
        return studentTab
    } else if (hasRoles() === 'coach') {
        return coachTab
    } else if (hasRoles() === 'manager') {
        return managerTab
    } else if (hasRoles() === 'pros-consulte') {
        return prosConsulteTab
    } else if (hasRoles() === 'admin') {
        return adminTab
    }
    return userTab
}