import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import {hasRoles} from '../security/hasRoles'


export const StudentRoute = ({component: Component, ...rest}) => {
    let history = useHistory()

    return (
        <Route {...rest} render= {(props) => (
            hasRoles() === 'student' ? <Component {...props} zoom={rest.zoom} setOpenSnackbar={rest.setOpenSnackbar} setText={rest.setText} setTransition={rest.setTransition} setSeverity={rest.setSeverity} user={rest.user} /> : history.goBack()
        )}/>
    )
}