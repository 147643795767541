import {
    Typography,
    Zoom,
    CircularProgress,
    Button,
    ButtonGroup,
    Toolbar,
    Box,
    Table,
    TablePagination,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Paper,
    TableContainer,
    TableBody,
    LinearProgress,
} from '@mui/material'
import React, { useEffect, useState, } from 'react'
import { useHistory } from 'react-router-dom'
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { ThemeProvider } from '@mui/styles'
import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles'
import { useToolboxes } from '../../hook/toolbox';
import { TransitionLeft } from '../../helper/utils';
import DeletedConfirmationPopup from '../../components/DeletedConfirmationPopup';
import { apiFetch } from '../../service/security/apiFetch';
import { handleFormError } from '../../service/security/error';
import ManageResourcesPopup from './AdminToolboxItem/ManageResourcesPopup';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {id: 'softSkills', numeric: false, disablePadding: false, label: 'Soft Skills'},
    {id: 'theme', numeric: true, disablePadding: false, label: 'Thème', },
    {id: 'title', numeric: true, disablePadding: false, label: 'Titre', },
    {id: 'description', numeric: true, disablePadding: false, label: 'Descriptif', },
    {id: 'type', numeric: true, disablePadding: false, label: 'Type', },
    {id: 'duration', numeric: true, disablePadding: false, label: 'Durée', },
    {id: 'date', numeric: true, disablePadding: false, label: 'Date', },
    {id: 'action', numeric: true, disablePadding: false, label: 'Actions', },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{color: '#01304A', fontWeight: 'bold'}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const { numSelected, handleAddToolboxPopup } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%', color: '#ECA914' }}
                variant="h4"
                id="tableTitle"
                component="div"
            >
                Boîte à outils
            </Typography>
            <Button color='primary' variant='contained' onClick={handleAddToolboxPopup}>Ajouter</Button>
        </Toolbar>
    );
};

export default function AdminToolbox({ zoom, setSeverity, setText, setTransition, setOpenSnackbar, setOpenBackdrop }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [page, setPage] = useState(0);
    const [openPopup, setOpenPopup] = useState(false)
    const [loading, setLoading] = useState(false)
    const [document, setDocument] = useState(false)
    const [disable, setDisable] = useState(false)
    const [selected, setSelected] = useState(false)
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [themeResource, setThemeResource] = useState('')
    const [type, setType] = useState('')
    const [softSkill, setSoftSkill] = useState([]);
    const [themes, setThemes] = useState([]);
    const [types, setTypes] = useState([]);
    const [softSkills, setSoftSkills] = useState([]);
    const [softSkillsRequestable, setSoftSkillsRequestable] = useState([]);
    const [error, setError] = useState({})
    const [state, setState] = useState({
        softSkill: '',
        theme: '',
        title: '',
        description: '',
        type: '',
        duration: '',
    })
    const [locale] = useState('frFR');
    let history = useHistory()

    const  {
        toolboxes,
        totalItems,
        view,
        fetchToolboxes,
        deleteToolboxes,
        createToolboxes,
        updateToolboxes,
    } = useToolboxes()

    useEffect(() => {
        (async () => {
            const fetch = await fetchToolboxes()
            if (fetch && fetch['hydra:description']) {
                setSeverity('error')
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                history.goBack()
            }
        })()
    }, [fetchToolboxes, setSeverity, setText, setTransition, setOpenSnackbar, history])

    useEffect(() => {
        (async () => {
            const fetchSoftSKills = await apiFetch('/soft_skills')
            if (fetchSoftSKills && fetchSoftSKills['hydra:description']) {
                setSeverity('error')
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                history.goBack()
            } else {
                setSoftSkills(fetchSoftSKills['hydra:member'])
            }
        })()
    }, [setSoftSkills, setSeverity, setText, setTransition, setOpenSnackbar, history])

    useEffect(() => {
        (async () => {
            const fetchTheme = await apiFetch('/resource_themes')
            if (fetchTheme && fetchTheme['hydra:description']) {
                setSeverity('error')
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                history.goBack()
            } else {
                setThemes(fetchTheme['hydra:member'])
            }
        })()
    }, [setThemes, setSeverity, setText, setTransition, setOpenSnackbar, history])

    useEffect(() => {
        (async () => {
            const fetchTypes = await apiFetch('/resource_types')
            if (fetchTypes && fetchTypes['hydra:description']) {
                setSeverity('error')
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                history.goBack()
            } else {
                setTypes(fetchTypes['hydra:member'])
            }
        })()
    }, [setTypes, setSeverity, setText, setTransition, setOpenSnackbar, history])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = async (event, newPage) => {
        setLoading(true)
        if (newPage > page) {
          await fetchToolboxes(view['hydra:next'])
        } else if (newPage < page) {
          await fetchToolboxes(view['hydra:previous'])
        }
        setLoading(false)
        setPage(newPage);
    };

    // Avoid a layout jump when reaching the last page with empty toolboxes.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * 30 - toolboxes.length) : 0;

    const handleAddToolboxPopup = () => {
        setOpenPopup(true)
    }

    const manageToolbox = async () => {
        setDisable(true)
        setError({})
        const title = state.title
        const duration = state.duration
        const description = state.description

        if (!softSkillsRequestable || !themeResource || !type) {
            setSeverity('error')
            setText('Erreur lors de l\'ajout de la ressource ! Vous devez avoir au moins 1 softSkill et ajouter un type et un thème ! ')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
            setDisable(false)
            return 
        }
        let createDocument = null
        if (document) {
            let dataPhoto = new FormData() 
            dataPhoto.append('file', document && document[0])
            createDocument = await apiFetch('/resource_objects', {
                method: 'POST',
                body: dataPhoto,
            }, true)
        }
        let body = {title: title, duration: duration, description: description, type: type, theme: themeResource, softSkills: softSkillsRequestable}
        if (document) {
            body.document = createDocument && createDocument['@id']
        }
        if (selected) {
            body['@id'] = selected['@id']
        }

        const manage = selected ? await updateToolboxes(selected.uuid, body, '/resources') : await createToolboxes(body, '/resources')
        if (manage && manage.uuid) {
            setText('La ressource a été ajoutée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setPage(0)
            setOpenPopup(false)
        } else {
            const formError = handleFormError(manage, ['softSkill.id', 'theme.name', 'title', 'description', 'type.name', 'duration'])
            setError(formError)
            setSeverity('error')
            setText('Erreur lors de l\'ajout de la ressource !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDisable(false)
    }

    const handleConfirmationDelete = (event, row) => {
        setOpenDeletePopup(!openDeletePopup)
        setSelected(row)

    }

    const handleDelete = async () => {
        setOpenDeletePopup(!openDeletePopup)
        setOpenBackdrop(true)
        const deletedCoach = await deleteToolboxes(selected.uuid)

        if (deletedCoach && deletedCoach.ok) {
            setText('La ressource a été supprimée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setPage(0)
        } else {
            setText((deletedCoach && deletedCoach['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setOpenBackdrop(false)
        setSelected(false)
    }

    const handleModifyPopup = (event, row) => {
        setState({
            title: row.title,
            description: row.description,
            duration: row.duration,
        })
        setType(row.type['@id'])
        setThemeResource(row.theme['@id'])
        setSoftSkill(formatSoftSkill(row.softSkills))
        setSoftSkillsRequestable(formatRequestableSoftSkills(row.softSkills))
        setSelected(row)
        setOpenPopup(true)
    }

    const handleClose = () => {
        setState({
            softSkill: '',
            theme: '',
            title: '',
            description: '',
            type: '',
            duration: '',
        })
        setType('')
        setThemeResource('')
        setSoftSkill([])
        setOpenPopup(false)
        setSelected(false)
    }

    const formatSoftSkill = (array) => {
        const arr = []
        array.map((item) => arr.push(item.name))
        return arr
    }

    const formatRequestableSoftSkills = (array) => {
        const arr = []
        array.map((item) => arr.push(item['@id']))
        return arr
    }

    return (
        <Zoom in={zoom} timeout={700}>
            <Box sx={{ width: '100%' }}>
                <Paper elevation={16} sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar handleAddToolboxPopup={handleAddToolboxPopup} />
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                    toolboxes.slice().sort(getComparator(order, orderBy)) */}
                                {toolboxes ? stableSort(toolboxes, getComparator(order, orderBy))
                                    /* .slice(page * 30, page * 30 + 30) */
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="normal"
                                                >
                                                    {row.softSkills && row.softSkills.map((el) => {
                                                        return el.name + ', '
                                                    })}
                                                </TableCell>
                                                <TableCell align="right">{row.theme.name}</TableCell>
                                                <TableCell align="right">{row.title}</TableCell>
                                                <TableCell align="right">{row.description}</TableCell>
                                                <TableCell align="right">{row.type.name}</TableCell>
                                                <TableCell align="right">{row.duration ? row.duration : 0} min</TableCell>
                                                <TableCell align="right">{row.date}</TableCell>
                                                <TableCell align="right">
                                                    <ButtonGroup variant='contained' color='secondary'>
                                                        <Button sx={{color: 'white'}} onClick={(event) => handleModifyPopup(event, row)}>
                                                            Modifier
                                                        </Button>
                                                        <Button sx={{color: 'white'}} onClick={(event) => handleConfirmationDelete(event, row)}>
                                                            Supprimer
                                                        </Button>
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        );
                                }) : <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={3} />
                                    <TableCell>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                        {toolboxes && <TablePagination
                            labelRowsPerPage=''
                            rowsPerPageOptions={[]}
                            component="div"
                            count={totalItems}
                            rowsPerPage={30}
                            nextIconButtonProps={{ disabled: loading || page >= (totalItems / 30) - 1}}
                            backIconButtonProps={{ disabled: loading || page === 0 }} 
                            page={page}
                            onPageChange={handleChangePage}
                        />}
                    </ThemeProvider>
                    {loading && <LinearProgress />}
                </Paper>
                <ManageResourcesPopup setSoftSkillsRequestable={setSoftSkillsRequestable} types={types} themes={themes} themeResource={themeResource} setThemeResource={setThemeResource} setType={setType} type={type} softSkills={softSkills} softSkill={softSkill} setSoftSkill={setSoftSkill} state={state} setState={setState} disable={disable} selected={selected} error={error} open={openPopup} handleClose={handleClose} document={document} setDocument={setDocument} handleAdd={manageToolbox} />
                <DeletedConfirmationPopup handleAccept={handleDelete} open={openDeletePopup} handleClose={handleConfirmationDelete} />
            </Box>
        </Zoom>
    );  
}